import { ROUTES } from "api";

import { baseApi } from "../baseAPI";

const flightsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlights: builder.query({
      query: () => ({
        url: ROUTES.flights,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["flight-list"],
    }),
    getFavoriteFlights: builder.query({
      query: () => ROUTES.favorites,
      transformResponse: (response) => response.data,
      providesTags: ["favorite-flight-list"],
    }),
    getFlight: builder.query({
      query: (id) => ({
        url: ROUTES["flights-details"] + id,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["flight"],
    }),
  }),
});

export const {
  useGetFlightsQuery,
  useGetFlightQuery,
  useGetFavoriteFlightsQuery,
} = flightsApi;
