import React from "react";
import { Box, Icon, Text } from "components";
import styled from "styled-components";

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: ${(props) => props.theme.spaces.xxl};
  font-weight: 500;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderBottom};
  position: relative;
  &:last-of-type {
    border-bottom: unset;
  }
`;

const Item = ({
  option,
  displayProperty,
  dropDownValue,
  handleDropDownItemSelect,
  getDropdownValue,
}) => {
  return (
    <ItemStyled onClick={() => handleDropDownItemSelect(option)}>
      <Box color="primary" position="absolute" left="20px" bottom="12px">
        {dropDownValue?.value === option.value && <Icon name="Check" />}
      </Box>
      <Text
        fontWeight="semibold"
        color={dropDownValue?.value === option?.value ? "primary" : "text"}
      >
        {getDropdownValue(displayProperty, option, null)}
      </Text>
    </ItemStyled>
  );
};

export default Item;
