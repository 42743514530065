import React, { useEffect, useState } from "react";
import CloudsImage from "assets/images/clouds.png";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import styled from "styled-components";

import FlightsCount from "./FlightsCount";
import FlightTabs from "./header/FlightTabs";
import Logos from "./header/Logos";
import SearchBox from "./header/SearchBox";
import Title from "./header/Title";

//DOM computed height
export const SEARCH_BOX_HEIGHT = 88;

const Header = ({
  scrollPosition,
  flightsCount,
  hasSearched,
  terminal,
  terminalsOptionList = [],
  noTerminalFlights,
  setQueryParams,
  showFavoriteTab,
}) => {
  const [scrollLimit, setScrollLimit] = useState(0);
  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
  });

  const { theme: settingsTheme } = settingsData;
  const logoURL = settingsTheme?.logo;

  useEffect(() => {
    const tabsElement = document.getElementById("tabs");

    if (tabsElement) {
      const dimensions = tabsElement.getBoundingClientRect();
      const marginTop = 5;
      const offset = dimensions.top - SEARCH_BOX_HEIGHT / 2 - marginTop;

      setScrollLimit(offset);
    }
  }, [logoURL]);

  const isFlightCountVisible = !!(hasSearched && flightsCount);
  const clampedScrollPosition = Math.min(scrollPosition, scrollLimit);

  return (
    <HeaderStyled
      id="header"
      url={CloudsImage}
      scrollPosition={-clampedScrollPosition}
    >
      <div className="image-background"></div>
      <Logos logoURL={logoURL} />
      <Title />
      <SearchBox
        isFlightCountVisible={isFlightCountVisible}
        terminal={terminal}
        terminalsOptionList={terminalsOptionList}
        setQueryParams={setQueryParams}
        backToTopOffset={clampedScrollPosition}
      />
      <FlightTabs
        backToTopOffset={clampedScrollPosition}
        showFavoriteTab={showFavoriteTab}
      />
      {isFlightCountVisible && (
        <FlightsCount
          flightsCount={flightsCount}
          noTerminalFlights={noTerminalFlights}
        />
      )}
    </HeaderStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const HeaderStyled = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  transform: translateY(${(props) => props.scrollPosition}px);
  z-index: ${(props) => props.theme.zIndex.fixedTableHead};
  color: ${(props) => props.theme.colors.textQuaternary};

  .image-background {
    position: absolute;
    background-color: ${(props) => props.theme.colors.secondary};
    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
    z-index: ${(props) => props.theme.zIndex.below};
  }
`;

export default Header;
