import { useEffect } from "react";
import { FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";

const useRefetchInterval = ({
  dispatch,
  setSelectedFlightDetails,
  reloadIntervalSeconds,
  refetchFlights,
}) => {
  useEffect(() => {
    //Reset selected flights data
    dispatch(setSelectedFlightDetails(null));

    const refetchInterval = setInterval(() => {
      refetchFlights();
    }, [reloadIntervalSeconds * 1000 || FLIGHTS_LIST_POLLING_INTERVAL]);

    return () => clearInterval(refetchInterval);
  }, [
    dispatch,
    refetchFlights,
    reloadIntervalSeconds,
    setSelectedFlightDetails,
  ]);
};

export default useRefetchInterval;
