import React from "react";
import { ReactComponent as PocketFlightsLogo } from "assets/images/pocket-flights-logo.svg";
import { Box } from "components";
import { VARIANTS } from "configs/constants";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import styled, { css, keyframes, useTheme } from "styled-components";

const LoadingIndicator = ({
  height,
  verticalOffset,
  variant = VARIANTS.LOADING_INDICATOR.STATIC,
}) => {
  const theme = useTheme();
  const { data: settingsData = {}, isLoading } = useGetSettingsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
    }
  );

  const renderContent = () => {
    const logoURL = settingsData.theme?.loading_icon;
    const logoWidth =
      settingsData.theme?.logo_width || theme.logo.realmLoadingLogoWidth;

    if (isLoading) {
      return null;
    }

    if (logoURL) {
      return (
        <LogoWrapper>
          <img width={logoWidth} src={logoURL} alt="logo" />
        </LogoWrapper>
      );
    }

    return (
      <LogoWrapper isLoading={isLoading}>
        <Logo fill={theme.colors.primary} />
      </LogoWrapper>
    );
  };

  return (
    <Box
      position={
        variant === VARIANTS.LOADING_INDICATOR.ABSOLUTE ? "absolute" : "static"
      }
      w="100%"
      h={height || "100vh"}
      d="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      top={
        variant === VARIANTS.LOADING_INDICATOR.ABSOLUTE
          ? `${verticalOffset + 100}px`
          : undefined
      }
      style={{
        zIndex:
          variant === VARIANTS.LOADING_INDICATOR.ABSOLUTE
            ? theme.zIndex.above
            : undefined,
      }}
    >
      {renderContent()}
    </Box>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const scaleUpDown = keyframes`
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
    }
  `;

const LogoWrapper = styled.div`
  ${({ isLoading }) =>
    !isLoading &&
    css`
      animation: ${scaleUpDown} 2s linear infinite;
    `}
`;

const Logo = styled(PocketFlightsLogo)`
  width: ${(props) => props.theme.logo.pocketFlightsLoadingLogoWidth};
`;

export default LoadingIndicator;
