import { useEffect } from "react";

export default function ClearStorage() {
  useEffect(() => {
    console.log(
      "clearing the local storage",
      localStorage.getItem("persist:pocketFIDS")
    );
    localStorage.clear();
  }, []);

  return <div className="clear-storage">Storage Cleared!</div>;
}
