import ClearStorage from "pages/ClearStorage";
import FlightDetailsPage from "pages/FlightDetailsPage";
import FlightsListPage from "pages/FlightsListPage";
import QrPage from "pages/QrPage";
import TerminalQRs from "pages/TerminalQRs";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FlightsListPage />} />
        <Route path="/flight/:id" element={<FlightDetailsPage />} />
        <Route path="/clear-storage" element={<ClearStorage />} />
        <Route path="/qr-code" element={<QrPage />} />
        <Route
          path="/terminal-qrs"
          element={<TerminalQRs terminalQRs={[]} />}
        />
        {/* TODO Implement better 404 page */}
        <Route path="*" element={<div>404 - Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
