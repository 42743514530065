import React from "react";
import { Box, Text } from "components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle } from "utils/arabisation.util";

const Title = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box pV="xxs" pH="m" pB="xl">
      <Text
        style={getTranslationsStyle(i18n)}
        mV="xs"
        fontSize="h1"
        color="textQuaternary"
      >
        {t("header.whatFlights")}
      </Text>
      <Text
        style={getTranslationsStyle(i18n)}
        fontWeight="normal"
        fontSize="m"
        mB="l"
      >
        {DateTime.now().setLocale(i18n.language).toFormat("cccc, d LLL y")}
      </Text>
    </Box>
  );
};

export default Title;
