import { useEffect, useState } from "react";
import { Box, Text } from "components";
import { VARIANTS } from "configs/constants";
import styled, { useTheme } from "styled-components";

const AirlineLogo = ({
  mediaUrl = "",
  airlineName,
  maximumRetriesCount = 0,
  retryInterval = 1000,
  imageWidth = 80,
  variant = VARIANTS.AIRLINE_LOGO.FLIGHTS_LIST,
}) => {
  const theme = useTheme();
  const [retryCount, setRetryCount] = useState(0);
  const [hasError, setHasError] = useState(false);

  const handleImageError = ({ currentTarget }) => {
    if (retryCount >= maximumRetriesCount) {
      setHasError(true);
    } else {
      setTimeout(() => {
        currentTarget.src = mediaUrl;
        setRetryCount(retryCount + 1);
      }, retryInterval);
    }
  };

  useEffect(() => {
    if (retryCount < maximumRetriesCount) {
      const retryTimeout = setTimeout(() => {
        setRetryCount(retryCount + 1);
      }, retryInterval);
      return () => clearTimeout(retryTimeout);
    }
  }, [maximumRetriesCount, retryCount, retryInterval]);

  return (
    <Box w={theme.airlineLogoWidth}>
      {hasError ? (
        <Box d="flex" alignItems="center" h="30px">
          <Text
            truncate
            textAlign={
              variant === VARIANTS.AIRLINE_LOGO.DETAILS_PAGE ? "center" : null
            }
          >
            {airlineName}
          </Text>
        </Box>
      ) : (
        <Box d="flex" alignItems="center" h="30px">
          <AirlineLogoStyled
            src={mediaUrl}
            width={imageWidth}
            onError={handleImageError}
            alt={airlineName}
            variant={variant}
          />
        </Box>
      )}
    </Box>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const AirlineLogoStyled = styled.img`
  border-radius: ${(props) => props.theme.borderRadius.base};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-top-left-radius: ${(props) =>
    props.variant === VARIANTS.AIRLINE_LOGO.DETAILS_PAGE ? "0" : undefined};
  border-top-right-radius: ${(props) =>
    props.variant === VARIANTS.AIRLINE_LOGO.DETAILS_PAGE ? "0" : undefined};
  border-top: ${(props) =>
    props.variant === VARIANTS.AIRLINE_LOGO.DETAILS_PAGE ? "unset" : undefined};
  margin-top: ${(props) =>
    props.variant === VARIANTS.AIRLINE_LOGO.DETAILS_PAGE ? "-1px" : undefined};
`;

export default AirlineLogo;
