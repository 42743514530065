import { useEffect, useState } from "react";

const useIsPWA = () => {
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkIsPWA = () => {
      if ("matchMedia" in window) {
        if (window.matchMedia("(display-mode: standalone)").matches) {
          setIsPWA(true);
        }
      } else if ("standalone" in navigator) {
        if (navigator.standalone) {
          setIsPWA(true);
        }
      }
    };

    checkIsPWA();

    window.addEventListener("beforeinstallprompt", checkIsPWA);
    window.addEventListener("appinstalled", checkIsPWA);

    return () => {
      window.removeEventListener("beforeinstallprompt", checkIsPWA);
      window.removeEventListener("appinstalled", checkIsPWA);
    };
  }, []);

  return isPWA;
};

export default useIsPWA;
