import React from "react";
import { ReactComponent as StarIcon } from "assets/images/star.svg";
import { Box, Text } from "components";
import Toast from "components/Reusable/Toast";
import { ACTIVE_TAB, TOAST_CONFIG } from "configs/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetFavoriteFlightsQuery } from "redux/queries/flights.query";
import {
  resetLastSelectedFlightItem,
  setActiveTab,
} from "redux/slices/flightsList.slice";
import {
  addFlightToFavorites,
  removeFlightFromFavorites,
} from "services/favorite-flights.service";
import { useTheme } from "styled-components";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const FavoriteFlights = ({ flight: detailsFlight }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { data: queryFavoriteFlights = [], refetch: refetchFavoriteFlights } =
    useGetFavoriteFlightsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const isCurrentFlightFavorite = !!queryFavoriteFlights.find(
    (favoriteFlight) => favoriteFlight === detailsFlight.ref_id,
  );

  const handleFavoriteFlightClick = () => {
    if (isCurrentFlightFavorite) {
      toast(
        <Toast
          type="success"
          position="top"
          message={t("favorite.removedFromFavorites")}
        />,
        TOAST_CONFIG,
      );
      removeFlightFromFavorites(detailsFlight.ref_id).then((res) => {
        refetchFavoriteFlights();
      });
    } else {
      dispatch(resetLastSelectedFlightItem());
      toast(
        <Toast
          type="success"
          position="top"
          message={t("favorite.addedToFavorites")}
        />,
        TOAST_CONFIG,
      );
      addFlightToFavorites(detailsFlight.ref_id).then(() => {
        refetchFavoriteFlights();
      });
      dispatch(setActiveTab(ACTIVE_TAB.FAVORITE));
    }
  };

  const shouldReverseOrder = isArabic(i18n);

  return (
    <Box mT="_xs" pH="m" w="100%" d="flex" direction="column" gap="xs">
      <Box
        w="100%"
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <Text style={getTranslationsStyle(i18n)} fontSize="m">
          {t("favorite.title")}
        </Text>
        <StarIcon
          onClick={handleFavoriteFlightClick}
          fill={isCurrentFlightFavorite ? theme.colors.primary : "none"}
          stroke={
            isCurrentFlightFavorite
              ? theme.colors.primary
              : theme.colors.textPrimary
          }
          width={24}
          height={24}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Text style={getTranslationsStyle(i18n)} color="textSecondary">
        {t("favorite.description")}
      </Text>
    </Box>
  );
};

export default FavoriteFlights;
