export const getFontFamily = (realm) => {
  switch (realm) {
    case "dxb":
      return "'Roboto', sans-serif";
    case "fra":
      return "'Roboto', sans-serif";
    case "lin":
      return "'Montserrat', sans-serif";
    case "mxp":
      return "'Montserrat', sans-serif";
    default:
      return "'Roboto', sans-serif";
  }
};
