import React from "react";
import { Box } from "components";

import AirportDetails from "./airportsAndVias/AirportDetails";
import ViaInformation from "./airportsAndVias/ViaInformation";

const AirportsAndVias = ({ type, airport, flightVias }) => {
  return (
    <Box d="flex" justifyContent="space-between">
      <AirportDetails type={type} airport={airport} direction="source" />
      <ViaInformation flights={flightVias} />
      <AirportDetails type={type} airport={airport} direction="destination" />
    </Box>
  );
};

export default AirportsAndVias;
