import { ROUTES } from "../../api";
import { baseApi } from "../baseAPI";

const settingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => ROUTES.settings,
      transformResponse: (response) => response.data,
      providesTags: ["settings"],
    }),
  }),
});

export const { useGetSettingsQuery } = settingsApi;
