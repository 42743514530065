import React from "react";
import { Box, Icon } from "components";
import styled from "styled-components";
import { getPlaneIconPositionCSS } from "utils/getPlaneIconPositionCSS";

const BulletStyled = styled.div`
  width: ${(props) => props.theme.spaces.s};
  height: ${(props) => props.theme.spaces.s};
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.primary};
`;

const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  border-top: 2px dashed ${(props) => props.theme.colors.primary};
`;
const PlaneIconContainerStyled = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  fill: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.backgroundQuaternary};
  transform: translateX(-50%);
`;

const PlaneLine = ({ type, statusText = "" }) => {
  return (
    <Box d="flex" alignItems="center" position="relative" mT="m" mB="s">
      <BulletStyled />
      <LineStyled />
      <PlaneIconContainerStyled
        left={getPlaneIconPositionCSS(type, statusText)}
      >
        <Icon color="primary" name="AircraftSide" size="xl" />
      </PlaneIconContainerStyled>
      <BulletStyled />
    </Box>
  );
};

export default PlaneLine;
