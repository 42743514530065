import { ROUTES } from "api";
import { getClientCredentials } from "services/auth.service";

import { baseApi } from "../baseAPI";

const analyticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    submitAnalytic: builder.mutation({
      // Use an async mutation function to handle the async logic
      queryFn: async (data, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const { uniqueDeviceIdentifier } = await getClientCredentials();

          const result = await fetchWithBQ({
            url: ROUTES.analytics,
            method: "POST",
            body: {
              ...data,
              device_id: uniqueDeviceIdentifier,
            },
          });

          if (result.error) {
            throw result.error;
          }

          return { data: result.data };
        } catch (error) {
          return { error: { status: "ANALYTICS_ERROR", error: error.message } };
        }
      },
      invalidatesTags: ["analytics"],
    }),
  }),
});

export const { useSubmitAnalyticMutation } = analyticsApi;
