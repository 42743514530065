import React from "react";
import { createRoot } from "react-dom/client";
//comment to trigger build on separate tag

import "./i18n";
import App from "./App";

const container = document.getElementById("root");
const rootComponent = createRoot(container);

rootComponent.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
