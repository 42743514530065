import styled, { css } from "styled-components";

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  height: 100%;
  overflow: scroll;
  border-top-right-radius: ${(props) => props.theme.borderRadius.m};
  border-top-left-radius: ${(props) => props.theme.borderRadius.m};
  padding: ${(props) => props.theme.spaces.m};
  padding-top: 0;
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.textTertiary};
  z-index: ${(props) => props.theme.zIndex.drawer};
  opacity: 0.2;
  width: 100vw;
  height: 100vh;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.drawer};
  transform: translateY(100vh);
  background-color: ${(props) => props.theme.colors.backgroundQuaternary};
  border-top-right-radius: ${(props) => props.theme.borderRadius.m};
  border-top-left-radius: ${(props) => props.theme.borderRadius.m};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.isOpen &&
    css`
      transform: ${(props) =>
        `translateY(calc(calc(100vh - ${props.minimumContentHeight}px) - ${props.translationAmount}px))`};
      transition: all 0.2s linear;

      ${Content} {
        box-shadow: ${props.theme.boxShadow.drawer};
      }
    `};
`;

const Drawer = ({
  isOpen,
  translationAmount,
  minimumContentHeight,
  children,
}) => {
  return (
    <>
      {isOpen && <Overlay />}
      <Wrapper
        isOpen={isOpen}
        translationAmount={translationAmount}
        minimumContentHeight={minimumContentHeight}
      >
        <Content>{children}</Content>
      </Wrapper>
    </>
  );
};

export default Drawer;
