import { useEffect } from "react";

const usePushNotification = ({
  refetchFlights,
  refetchSettings,
  dispatch,
  setSelectedFlightDetails,
  filteredFlights,
}) => {
  useEffect(() => {
    //Catch any push notifications and refetch the flight
    if ("serviceWorker" in navigator) {
      const handleMessage = (event) => {
        const { type } = event.data;
        const flight = event.data?.payload?.flight;

        if (type === "PUSH_NOTIFICATION") {
          refetchFlights();
          refetchSettings();
        }
        if (type === "CLICKED_ON_NOTIFICATION" && flight) {
          dispatch(
            setSelectedFlightDetails(
              filteredFlights?.find((_flight) => _flight.ref_id === flight)
            )
          );
          window.location.href = `${window.location.origin}/flight/${flight}`;
        }
      };

      navigator.serviceWorker?.addEventListener("message", handleMessage);

      return () => {
        navigator.serviceWorker?.removeEventListener("message", handleMessage);
      };
    }
  }, []);
};

export default usePushNotification;
