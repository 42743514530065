import forEach from "lodash/forEach";
import get from "lodash/get";
import transform from "lodash/transform";
import { css } from "styled-components";
import { darken, lighten, transparentize } from "polished";
import { useEffect } from "react";

/**
 * Scale a value in px or rem
 * @param {string} val - The value in px or rem
 * @param {number} scale - Scale factor
 * @param {bool} floor - Round down the value to the nearest integer
 * @returns {string} Scaled value in the original unit
 */
export function scaleSpacing(val, scale, floor = false) {
  let unit;
  if (/([0-9]+)\.?([0-9]*)\s*px/.test(val)) {
    unit = "px";
  } else if (/([0-9]+)\.?([0-9]*)\s*rem/.test(val)) {
    unit = "rem";
  }
  const value = parseFloat(val) * scale;
  return unit ? `${floor ? Math.floor(value) : value}${unit}` : val;
}

/**
 * Get a color in HEX from the theme
 * @param {obj} props - The props passed to the component
 * @param {string} propName - the name of the prop that we're getting the value from
 * @param {string} fallbackColorName - the name of the color used as fallback: prop missing or wrong
 * @returns {string} HEX color value or "inherit"
 */
export function getColor(props, propName, fallbackColorName) {
  if (get(props.theme.colors, props[propName])) {
    return get(props.theme.colors, props[propName]);
  }

  // pass keywords through
  if (
    fallbackColorName === "transparent" ||
    fallbackColorName === "inherit" ||
    fallbackColorName === "currentColor"
  ) {
    return fallbackColorName;
  }

  const fallbackColor = get(props.theme.colors, fallbackColorName);
  return fallbackColor || "inherit";
}

/**
 * In some cases we want to have the option to pass a color directly in hex.
 * For example we already have a button that accepts a `color` prop. To replace that with a value in Hex, we would pass in a prop called 'colorHex'
 * Use this function where you want to be able to have both props working with the existing code
 * @param {obj} props - The props passed to the component
 * @param {string} propName - the name of the prop that we're getting the value from
 * @param {string} fallbackColorName - the name of the color used as fallback: prop missing or wrong
 * @returns {string} HEX color value or "inherit"
 */
export function getHexOrColor(props, propName, fallbackColorName) {
  // first check if we are passing the exact color in Hex - this is needed for setting airline colors for example
  if (props[`${propName}Hex`]) {
    return props[`${propName}Hex`];
  }

  return getColor(props, propName, fallbackColorName);
}

/**
 * Get a spacing value with unit, from a given spacing name
 * @param {obj} props - The props passed to the component
 * @param {string} propName - the name of the prop that we're getting the name of the space type from
 * @param {string} fallbackSpaceName - the name of the space used as fallback: prop missing or wrong will fallback to spaces.base
 * @returns {string} The spacing value as px or rem value
 */
export function getSpace(props, propName, fallbackSpaceName) {
  if (
    props[propName] &&
    Object.keys(props.theme.spaces).includes(props[propName])
  ) {
    return props.theme.spaces[props[propName]];
  }

  // will return fallbackSpaceName if it's valid or spaces.base
  return fallbackSpaceName &&
    Object.keys(props.theme.spaces).includes(fallbackSpaceName)
    ? props.theme.spaces[fallbackSpaceName]
    : props.theme.spaces.base;
}

/**
 * Converts a CSS hex color value to RGBA.
 * @param {string} hex - Expanded hexadecimal CSS color value.
 * @param {number} alpha - Alpha as a decimal.
 * @returns {string} RGBA CSS color value.
 */
export function hex2Rgba(hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const darkenSafe = (value, color) => {
  return ["transparent", "inherit", "currentColor"].includes(color)
    ? color
    : darken(value, color);
};

export const lightenSafe = (value, color) => {
  return ["transparent", "inherit", "currentColor"].includes(color)
    ? color
    : lighten(value, color);
};

export const transparentizeSafe = (opacity, color) =>
  ["transparent", "inherit", "currentColor"].includes(color)
    ? color
    : transparentize(opacity, color);

/* Media Query utility ******************************************************************** */
export const mediaQuerySetup = (breakpoints) =>
  Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media ${breakpoints[label]} {
        ${css(...args)};
      }
    `;
    return acc;
  }, {});

// Custom match function inspired by https://github.com/moroshko/autosuggest-highlight/issues/5
export const matcher = (text, query) =>
  query
    .trim()
    // Splitting by whitespace
    .split(/\s+/)
    // Filter out blank query
    .filter((word) => word.length > 0)
    .reduce((result, word) => {
      let index = text.match(
        new RegExp(word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
      );

      index = index ? index.index : -1;

      if (index > -1) {
        result.push([index, index + word.length]);

        // Replace what we just found with spaces so we don't find it again.
        // eslint-disable-next-line no-param-reassign
        text =
          text.slice(0, index) +
          new Array(word.length + 1).join(" ") +
          text.slice(index + word.length);
      }

      return result;
    }, [])
    .sort((match1, match2) => match1[0] - match2[0]);

// Get the className based on available props.
export const getClassNameFromProps = ({
  isActive,
  isDisabled,
  isHighlighted,
  submenuOpen,
}) => {
  let className = "";

  if (isActive) {
    className += " is-active";
  }

  if (isDisabled) {
    className += " is-disabled";
  }

  if (isHighlighted) {
    className += " is-highlighted";
  }

  if (submenuOpen) {
    className += " is-submenu-open";
  }

  return className;
};

export const getDataHookProp = (automationHook) =>
  automationHook
    ? {
        "data-hook": automationHook,
      }
    : {};

export const getEnv = (envKey) => {
  const value = window._env_?.[envKey] || process?.env[envKey];

  if (!value) {
    throw new Error(`Missing env key ${envKey}`);
  }

  return value;
};

export const labelKebab = (label) =>
  label
    ?.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export const useDeprecate = (props, componentName) => {
  if (!componentName) {
    throw new Error("You must provide a component name to useDeprecate");
  }

  useEffect(() => {
    const warnings = transform(
      props,
      (acc, value, key) => {
        if (value !== undefined) {
          acc.push(
            `DEPRECATED: The "${componentName}" prop "${key}" is deprecated. Please use "${key
              .replace(/arginBottom/g, "B")
              .replace(/idth/g, "")
              .replace(/eight/g, "")}"`
          );
        }
        return acc;
      },
      []
    );

    if (warnings.length > 0) {
      // removed group because it was showing up all the time
      forEach(warnings, console.warn);
    }
  }, [props]);
};
