function setAntiScrapingCookie() {
  const cookieValue =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  document.cookie = "anti_scraping_cookie=" + cookieValue + "; path=/";
}

setAntiScrapingCookie();

function isHeadless() {
  return (
    window.navigator.userAgent.includes("Headless") ||
    window.navigator.webdriver
  );
}

function checkAntiScrapingCookie() {
  const cookieRegex = /anti_scraping_cookie=([^;]+)/;
  const match = cookieRegex.exec(document.cookie);
  return match && match[1];
}

export function checkAntiScraping() {
  return checkAntiScrapingCookie() && !isHeadless();
}
