import { Box, SecondaryButton, Text } from "components";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import useWindowDimensions from "hooks/windowDimensions";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import { getTranslationsStyle } from "utils/arabisation.util";

const QrPage = () => {
  const { t, i18n } = useTranslation();
  
  useTrackPageAnalytics({
    name: "QR Page",
  });
  const { width } = useWindowDimensions();

  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnReconnect: true,
  });
  const QrLogo = settingsData.theme?.qr_logo;

  const isMobile = width < 800 ? "mobile" : "";

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-element");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Box
      m={isMobile ? "m" : "xxl"}
      p="l"
      d="flex"
      direction="column"
      alignItems="center"
      backgroundColor="primary"
      borderRadius="m"
      maxW="800px"
    >
      <Text color="textQuaternary" fontSize="xl" mB="s">
        Pocket Flights
      </Text>
      <QRCode
        id="qr-element"
        size={Math.min(width / 2, 500)}
        value={`${window.location.origin}`}
        eyeRadius={[
          {
            outer: 30,
            inner: 0,
          },
          {
            outer: 30,
            inner: 0,
          },
          {
            outer: 30,
            inner: 0,
          },
        ]}
        enableCORS={true}
        quietZone={width / 30}
        logoWidth={Math.min(width / 8, 100)}
        logoHeight={Math.min(width / 8, 100)}
        logoImage={QrLogo}
      />
      <Box mT="l">
        <SecondaryButton onClick={downloadQRCode} className="download">
          <Text
            style={getTranslationsStyle(i18n)}
            fontSize="l"
            fontWeight="semibold"
          >
            {t("qr_pages.download")}
          </Text>
        </SecondaryButton>
      </Box>
    </Box>
  );
};
export default QrPage;
