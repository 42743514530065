import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock.svg";
import { ReactComponent as FootprintsIcon } from "assets/images/footprints.svg";
import { ReactComponent as HourglassIcon } from "assets/images/hourglass.svg";
import { ReactComponent as LuggageIcon } from "assets/images/luggage.svg";
import { ReactComponent as PlaneUpIcon } from "assets/images/plane-up.svg";
import { ReactComponent as ScalingIcon } from "assets/images/scaling.svg";
import { ReactComponent as UserCheckIcon } from "assets/images/user-check.svg";
import { Box, Text } from "components";
import theme from "components/theme";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const iconsMap = {
  gate_info: PlaneUpIcon,
  immigration_wait_display: ClockIcon,
  security_wait_display: HourglassIcon,
  gate_distance: FootprintsIcon,
  checkin_close: UserCheckIcon,
  status: CalendarIcon,
  baggage_info: LuggageIcon,
  oversized_belt: ScalingIcon,
};

const flightInfo = (key, value, type, t) => {
  //TODO Translate the value from back-end
  const IconComponent = iconsMap[key];

  let label = "";
  let text = "";
  let boldText = "";

  switch (key) {
    case "status":
      label = t("general_info.status_label");
      text = `${value || ""}`;
      boldText = null;
      break;

    case "gate_info":
      label = t("general_info.gateInfo_label");
      text = t("general_info.gateInfo_text");
      boldText = `${value || ""}`;
      break;

    case "immigration_wait_display":
      label = `${
        type === "departure"
          ? t("general_info.immigrationWaitDisplay_emigration")
          : t("general_info.immigrationWaitDisplay_immigration")
      } ${t("general_info.immigrationWaitDisplay_queueTime")}`;

      text = t("general_info.immigrationWaitDisplay_text");

      boldText = `${value || ""}`;
      break;

    case "security_wait_display":
      label = t("general_info.securityWaitDisplay_label");
      text = t("general_info.securityWaitDisplay_text");
      boldText = `${value || ""}`;
      break;

    case "gate_distance":
      label = t("general_info.gateDistance_label");
      text = t("general_info.gateDistance_text");
      boldText = `${value || ""}`;
      break;

    case "checkin_close":
      label = t("general_info.checkinClose_label");
      text = t("general_info.checkinClose_text");
      boldText = `${DateTime.fromISO(value).toFormat("HH:mm")}`;
      break;

    case "oversized_belt":
      label = t("dynamic_texts.oversized_label");
      text = t("dynamic_texts.oversized_text");
      boldText = `${value}`;
      break;

    case "baggage_info":
      label = "";
      text = "";
      boldText = "";
      break;

    default:
      break;
  }

  return { label, text, boldText, IconComponent };
};

const MessageBoxHeaderStyled = styled.div`
  border-top: ${(props) => props.theme.messageBox.borderWidth} solid
    ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
  gap: ${(props) => props.theme.spaces.m};
  display: flex;
  flex-direction: column;
`;

const GeneralInfo = ({ flight }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <MessageBoxHeaderStyled>
        {flight.info &&
          Object.entries(flight.info)?.map(([key, value]) => {
            const { label, text, boldText, IconComponent } = flightInfo(
              key,
              value,
              flight.type,
              t
            );

            if (!IconComponent) {
              return null;
            }

            if (!boldText && label !== t("general_info.status_label") && text) {
              return null;
            }

            return (
              <Box key={key}>
                <Text
                  style={getTranslationsStyle(i18n)}
                  mB="s"
                  color="textSecondary"
                  fontSize="s"
                >
                  {label}
                </Text>
                <Box
                  style={getTranslationsStyle(i18n, {
                    marginLeft: theme.spaces.base,
                  })}
                  d="flex"
                  alignItems="center"
                >
                  <Box
                  // This Box is used to prevent Icon Shrinking
                  >
                    <IconComponent />
                  </Box>
                  <Text
                    fontSize="m"
                    mL={isArabic(i18n) ? undefined : "s"}
                    mR={isArabic(i18n) ? "s" : undefined}
                  >
                    {text}
                    {text && (
                      <Text as="span" fontWeight="bold">
                        &nbsp;{boldText}
                      </Text>
                    )}
                  </Text>
                </Box>
              </Box>
            );
          })}
      </MessageBoxHeaderStyled>
    </>
  );
};

export default GeneralInfo;
