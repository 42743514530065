import React from "react";
import { Tab } from "components";
import Tabs from "components/smaltOverwritten/Tabs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  flightsListSelector,
  setActiveTab,
  triggerScrollToTop,
} from "redux/slices/flightsList.slice";
import styled from "styled-components";
import { isArabic } from "utils/arabisation.util";

//DOM computed height
export const FLIGHT_TABS_HEIGHT = 109.79;

const FlightTabs = ({ showFavoriteTab, backToTopOffset }) => {
  const { activeTab } = useSelector(flightsListSelector);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    dispatch(triggerScrollToTop(tab === "favorite" ? 0 : backToTopOffset));
    dispatch(setActiveTab(tab));
  };

  const shouldReverseOrder = isArabic(i18n);
  // const shouldReverseOrder = true;

  if (shouldReverseOrder) {
    return (
      <FlightTabsStyled id="tabs">
        <Tabs
          isArabicLayout
          activeTab={activeTab}
          initialActive={activeTab}
          withPanels={false}
          onTabClick={handleTabClick}
        >
          {/* TODO Find out if the styling for each tab needs to be changed to RTL writing direction,
              If so, the Tab component needs to be overwritten as well
          */}
          <Tab title={t("header.tab_arrivals")} value="arrival"></Tab>
          <Tab title={t("header.tab_departures")} value="departure"></Tab>
          <Tab title={t("header.tab_allFlights")} value="all"></Tab>
          {showFavoriteTab && (
            <Tab title={t("header.tab_favorites")} value="favorite"></Tab>
          )}
        </Tabs>
      </FlightTabsStyled>
    );
  }

  return (
    <FlightTabsStyled id="tabs">
      <Tabs
        activeTab={activeTab}
        initialActive={activeTab}
        withPanels={false}
        onTabClick={handleTabClick}
        style={{ backgroundColor: "red" }}
      >
        {showFavoriteTab && (
          <Tab title={t("header.tab_favorites")} value="favorite"></Tab>
        )}
        <Tab title={t("header.tab_allFlights")} value="all"></Tab>
        <Tab title={t("header.tab_departures")} value="departure"></Tab>
        <Tab title={t("header.tab_arrivals")} value="arrival"></Tab>
      </Tabs>
    </FlightTabsStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const FlightTabsStyled = styled.div`
  padding-top: 46px;
  padding-bottom: 10px;
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
`;

export default FlightTabs;
