import { ROUTES } from "api";
import { setInitialData } from "redux/slices/initialize.slice";
import store from "redux/store";

export async function getClientCredentials() {
  const initializeStoreData = store.getState().initialize;

  if (!Object.keys(initializeStoreData).length) {
    try {
      const response = await fetch(
        window._env_?.REACT_APP_API_URL +
          ROUTES.initialize +
          "?" +
          new URLSearchParams({
            realm: window._env_?.REACT_APP_REALM,
          }).toString(),
        {
          method: "GET",
          headers: {
            "auth-key": window._env_?.REACT_APP_SHARED_KEY,
          },
        }
      );

      let parsedResponse = await response.json();
      parsedResponse = parsedResponse.data;

      store.dispatch(setInitialData(parsedResponse));

      return {
        clientId: parsedResponse?.clientId,
        clientSecret: parsedResponse?.clientSecret,
        publicKey: parsedResponse?.publicKey,
        uniqueDeviceIdentifier: parsedResponse?.uniqueDeviceIdentifier,
      };
    } catch (error) {
      console.log(
        "Something went wrong while trying to get initialize data. Error: ",
        error
      );
      return {};
    }
  }

  return {
    clientId: initializeStoreData.clientId,
    clientSecret: initializeStoreData.clientSecret,
    publicKey: initializeStoreData?.publicKey,
    uniqueDeviceIdentifier: initializeStoreData?.uniqueDeviceIdentifier,
  };
}
