import React from "react";
import { ReactComponent as CircleVia } from "assets/images/circle-via.svg";
import { Box, Icon, Text } from "components";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { isArabic } from "utils/arabisation.util";

const SHORTENED_TEXT_THRESHOLD = 24;

const ViaContainerStyled = styled.div`
  display: flex;
  margin-left: 5px;
  margin-right: ${(props) => (props.shouldReverseOrder ? "4px" : undefined)};
  margin-top: -3px;
  flex-direction: ${(props) =>
    props.shouldReverseOrder ? "row-reverse" : undefined};
`;
const ViaTextStyled = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: ${(props) => (props.shouldReverseOrder ? "auto" : "14px")};
  margin-right: ${(props) => (props.shouldReverseOrder ? "14px" : undefined)};
  direction: ${(props) => (props.shouldReverseOrder ? "rtl" : "ltr")};
`;

const Content = ({ type, via, destination, origin, number }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const shouldReverseOrder = isArabic(i18n);
  return (
    <Box mH="s" mB="s">
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <Box
          d="flex"
          gap={shouldReverseOrder ? "s" : undefined}
          direction={shouldReverseOrder ? "row-reverse" : undefined}
        >
          <Icon
            color="primary"
            name={type === "departure" ? "FlightTakeoff" : "FlightLand"}
            size="l"
          />
          <Text
            mL="xs"
            fontSize={
              destination?.length > SHORTENED_TEXT_THRESHOLD ||
              origin?.length > SHORTENED_TEXT_THRESHOLD
                ? "base"
                : "l"
            }
            fontWeight="semibold"
          >
            {type === "departure" ? destination : origin}
          </Text>
        </Box>
        <Text fontWeight="base" fontSize="l">
          {number}
        </Text>
      </Box>
      {via && (
        <ViaContainerStyled shouldReverseOrder={shouldReverseOrder}>
          <CircleVia stroke={theme.colors.primary} />
          <ViaTextStyled shouldReverseOrder={shouldReverseOrder}>
            {via.length > 1
              ? `${via.length} ${t(
                  "flights_list.flightItem_content_numberOfStops"
                )}`
              : `${t("flights_list.flightItem_content_Via")} ${via[0]?.name}`}
          </ViaTextStyled>
        </ViaContainerStyled>
      )}
    </Box>
  );
};

export default Content;
