import { useState } from "react";
import { Modal } from "components";
import { NOTIFICATION_CASES } from "configs/constants";
import useIOSDetection from "hooks/useIOSDetection";
import useIsPWA from "hooks/useIsPWA";
import { useTheme } from "styled-components";

import NotificationInstructions from "./pushNotificationBox/NotificationInstructions";
import NotificationInstructionsModalContent from "./pushNotificationBox/NotificationInstructionsModalContent";

const PushNotificationBox = ({ flightId }) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);

  const { isMacOS, iOSVersion, isIOSVersionGreaterThan16_4, isMobileSafari } =
    useIOSDetection();
  const isPWA = useIsPWA();
  const isRegularDevice = !iOSVersion;
  const isMobile = /Mobi/i.test(window.navigator?.userAgent);

  const handleOpenNotificationsModal = (notificationType) => {
    setSelectedNotificationType(notificationType);
    setIsModalOpen(true);
  };
  const getNotificationType = () => {
    //Has iPhone but it's OS version is below 16.4
    if (iOSVersion && !isIOSVersionGreaterThan16_4 && !isMacOS) {
      return NOTIFICATION_CASES.IOS_VERSION_BELOW_16_4;
    }
    //Has iPhone eligible for notifications but the default browser is not Safari
    if (
      !isMobileSafari &&
      iOSVersion &&
      isIOSVersionGreaterThan16_4 &&
      !isMacOS
    ) {
      return NOTIFICATION_CASES.DEFAULT_BROWSER_NOT_SAFARI;
    }
    //Has iPhone eligible for notifications but the App is not installed as PWA
    if (
      !isPWA &&
      isMobileSafari &&
      iOSVersion &&
      isIOSVersionGreaterThan16_4 &&
      !isMacOS
    ) {
      return NOTIFICATION_CASES.APP_NOT_INSTALLED_AS_PWA;
    }
    //Has android but the App is not installed
    if (isMobile && !isPWA && isRegularDevice) {
      return NOTIFICATION_CASES.IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA;
    }
    //Has phone eligible for notifications and the App is installed OR the user is not browsing on mobile
    if (
      (isPWA || !isMobile) &&
      ((isMobileSafari &&
        iOSVersion &&
        isIOSVersionGreaterThan16_4 &&
        !isMacOS) ||
        isRegularDevice ||
        isMacOS)
    ) {
      return NOTIFICATION_CASES.USER_IS_NOT_BROWSING_MOBILE;
    }
    return null;
  };

  const notificationType = getNotificationType();

  if (notificationType !== null) {
    return (
      <>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          withCloseButton={false}
          w={`calc(100% - ${theme.spaces.xxl})`}
          verticalPosition="center"
        >
          <NotificationInstructionsModalContent
            notificationType={selectedNotificationType}
            handleCloseNotificationsModal={setIsModalOpen}
          />
        </Modal>
        <NotificationInstructions
          handleOpenNotificationsModal={handleOpenNotificationsModal}
          flightId={flightId}
          notificationType={notificationType}
        />
      </>
    );
  }

  return null;
};

export default PushNotificationBox;
