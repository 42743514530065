import * as React from "react";
import PropTypes from "prop-types";

import IconWrapper, { iconStylePropTypes } from "../../IconWrapper";
export const SvgInfo = ({ title, titleId }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    aria-labelledby={titleId}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M30.667 16c0-4.049-1.643-7.719-4.296-10.371S20.05 1.333 16 1.333 8.281 2.976 5.629 5.629 1.333 11.95 1.333 16s1.643 7.719 4.296 10.371S11.95 30.667 16 30.667s7.719-1.643 10.371-4.296S30.667 20.05 30.667 16zM28 16c0 3.315-1.341 6.312-3.515 8.485S19.314 28 16 28s-6.312-1.341-8.485-3.515S4 19.314 4 16s1.341-6.312 3.515-8.485S12.686 4 16 4s6.312 1.341 8.485 3.515S28 12.686 28 16zm-10.667 5.333V16a1.333 1.333 0 00-2.666 0v5.333a1.333 1.333 0 002.666 0zM16 12a1.333 1.333 0 100-2.667A1.333 1.333 0 0016 12z" />
  </svg>
);
SvgInfo.propTypes = {
  title: PropTypes.string,
  titleId: PropTypes.string,
};
const InfoIcon = ({ title, ...props }) => (
  <IconWrapper {...props}>
    <SvgInfo title={title || `Info Icon`} titleId="SvgInfo-Id" />
  </IconWrapper>
);
InfoIcon.propTypes = iconStylePropTypes;
export default InfoIcon;
