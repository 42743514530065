import React from "react";
import { ToastContainer as ReactToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const StyledToastContainer = styled(ReactToastContainer)`
  .Toastify__toast {
    background-color: transparent;
    margin: 0 ${(props) => props.theme.spaces.m};
    padding: 0;
    border: 0;
  }
  .Toastify__toast-body {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    color: ${(props) => props.theme.colors.otherText};
  }
`;

const ToastContainer = ({ ...props }) => {
  return <StyledToastContainer {...props} />;
};

export default ToastContainer;
