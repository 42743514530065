import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSubmitAnalyticMutation } from "redux/queries/analytics";

const useTrackPageAnalytics = (initialAnalyticData) => {
  const [submitAnalytic, { error }] = useSubmitAnalyticMutation();
  const { pathname, search } = useLocation();

  const URI = pathname + search;
  const lastSentURI = useRef(null);

  useEffect(() => {
    // Check if analytics data exists and the current URI hasn't been tracked yet
    if (initialAnalyticData && lastSentURI.current !== URI) {
      submitAnalytic({
        type: "hit",
        uri: URI,
        ...initialAnalyticData,
      });
      lastSentURI.current = URI;
    }
  }, [initialAnalyticData, URI, submitAnalytic]);

  const sendAnalytics = (analyticData) => {
    submitAnalytic(analyticData);
  };

  if (error) {
    console.error("Could not send analytics: " + JSON.stringify(error));
  }

  return { sendAnalytics };
};

export default useTrackPageAnalytics;
