import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { baseApi } from "./baseAPI";
import flightsListReducer from "./slices/flightsList.slice";
import initializeReducer from "./slices/initialize.slice";
import languageDrawerReducer from "./slices/languageDrawer.slice";
import notificationsReducer from "./slices/notifications.slice";

const persistConfig = {
  key: "pocketFlights",
  storage: storage,
  whitelist: ["notifications", "initialize"],
};

const reducers = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  initialize: initializeReducer,
  flightsList: flightsListReducer,
  notifications: notificationsReducer,
  languageDrawer: languageDrawerReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export default store;
