import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      errorStack: null,
      copySuccess: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error, errorStack: error.stack };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Implement error logging here.
  }

  render() {
    if (this.state.hasError) {
      return (
        <this.props.fallback
          error={this.state.error}
          errorStack={this.state.errorStack}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
