import React from "react";
import styled from "styled-components";

import ArrivalsInfo from "./flightInfoBox/ArrivalsInfo";
import CustomCheckin from "./flightInfoBox/CustomCheckin";
import DeparturesInfo from "./flightInfoBox/DeparturesInfo";

const TicketInfoBoxStyled = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const FlightInfoBox = ({ flight }) => {
  const isEKCheckin = flight.checkin_map?.length;
  return (
    <>
      <TicketInfoBoxStyled>
        {flight?.type === "departure" ? (
          <DeparturesInfo
            checkin={flight.checkin}
            gate={flight.gate}
            terminalName={flight.terminal_name}
            hideCheckin={isEKCheckin}
          />
        ) : (
          <ArrivalsInfo belt={flight?.belt} terminal={flight?.terminal_name} />
        )}
      </TicketInfoBoxStyled>
      {isEKCheckin && <CustomCheckin checkins={flight.checkin_map} />}
    </>
  );
};

export default FlightInfoBox;
