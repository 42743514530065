// TranslationsError.jsx
import React from "react";
import { ReactComponent as ErrorIllustration } from "assets/images/error-illustration.svg";
import { ReactComponent as PocketFlightsLogo } from "assets/images/pocket-flights-logo.svg";
import { Box, Heading1, PrimaryButton, Text } from "components";
import { useTheme } from "styled-components";

const TranslationsError = ({ error, resetErrorBoundary }) => {
  const theme = useTheme();

  return (
    <Box
      minH="100vh"
      d="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box flex="0.2" d="flex" justifyContent="center" alignItems="center">
        <PocketFlightsLogo
          width={
            theme.logo.pocketFlightsLogoWidth || theme.logo.pocketFlightsWidth
          }
          height={
            theme.logo.pocketFlightsLogoHeight || theme.logo.pocketFlightsHeight
          }
          fill={theme.colors.primary}
        />
      </Box>
      <Box
        flex="1"
        d="flex"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="s"
        pH="m"
      >
        <Heading1>Oops! Something went wrong.</Heading1>
        <Text p="m" textAlign="center" fontSize="l" color="gray">
          We couldn't load the translations.
          <br />
          Please refresh the page to try again.
        </Text>
        <ErrorIllustration
          style={{ marginTop: "50px", marginBottom: "20px" }}
        />
        <PrimaryButton onClick={resetErrorBoundary}>
          <Text fontWeight="semibold">Refresh the page</Text>
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default TranslationsError;
