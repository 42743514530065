import { LANGUAGE_CODES } from "configs/constants";

export const isArabic = (i18n) => {
  return i18n.language === LANGUAGE_CODES.ARABIC;
};

export const getTranslationsStyle = (i18n, extraStyle) => {
  switch (i18n.language) {
    case LANGUAGE_CODES.ARABIC:
      return {
        direction: isArabic(i18n) ? "rtl" : "ltr",
        textAlign: "start",
        ...extraStyle,
      };
    default:
      return null;
  }
};
