import React from "react";
import { Box, Text } from "components";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

//DOM computed height
export const FLIGHTS_COUNT_HEIGHT = 26.19;

const FlightsCount = ({ flightsCount, noTerminalFlights }) => {
  const { t, i18n } = useTranslation();
  const flightPlural =
    flightsCount > 1
      ? t("flights_list.flightsCount_flights")
      : t("flights_list.flightsCount_flight");

  const _isArabic = isArabic(i18n);

  return (
    <Box
      d="flex"
      pL="m"
      pR={_isArabic ? "m" : undefined}
      pB="xs"
      backgroundColor="backgroundTertiary"
      style={getTranslationsStyle(i18n)}
    >
      <Text color="text" style={getTranslationsStyle(i18n)}>
        {flightsCount}&nbsp;
        {flightPlural}&nbsp;
        {noTerminalFlights
          ? " " + t("flights_list.flightsCount_inOtherTerminals")
          : undefined}
      </Text>
    </Box>
  );
};

export default FlightsCount;
