import React from "react";
import { Box, Icon, Text } from "components";
import { DateTime } from "luxon";
import Status from "pages/flightList/flightsContainer/flightItem/header/Status";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const TimeInformation = ({ type, flightTime, newTime, status }) => {
  const { t, i18n } = useTranslation();
  const shouldReverseOrder = isArabic(i18n);
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      direction={shouldReverseOrder ? "row-reverse" : undefined}
    >
      <Box d="flex" direction="column" gap="s">
        <Text
          color="textPrimary"
          fontWeight="semibold"
          style={getTranslationsStyle(i18n)}
        >
          {type === "departure"
            ? t("details.header_timeInformation_departingAt")
            : t("details.header_timeInformation_arrivingAt")}
        </Text>
        <Box d="flex" alignItems="center" gap="xxs">
          <Text
            textDecoration={newTime && "line-through"}
            color="textTertiary"
            fontSize="xl"
            fontWeight="bold"
          >
            {DateTime.fromISO(flightTime).toFormat("HH:mm")}
          </Text>
          {newTime && (
            <Box d="flex" gap="xxs" alignItems="center" justifyContent="center">
              <Icon color="accent_1" size="xl" name="ArrowRight" />
              <Text color="accent_1" fontSize="xl" fontWeight="bold">
                {DateTime.fromISO(newTime).toFormat("HH:mm")}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box d="flex" direction="column" gap="s">
        <Text
          color="textPrimary"
          fontWeight="semibold"
          style={getTranslationsStyle(i18n)}
        >
          {t("details.header_status")}
        </Text>
        <Status status={status} />
      </Box>
    </Box>
  );
};

export default TimeInformation;
