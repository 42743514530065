import { scaleSpacing } from "../helpers";

/**
 * This is the default theme.
 * It is configured to the Theme from the AODB Project
 */

const baseSpace = "16px";

const spaces = {
  none: scaleSpacing(baseSpace, 0),
  xxs: scaleSpacing(baseSpace, 0.25), // 4
  xs: scaleSpacing(baseSpace, 0.5), // 8
  s: scaleSpacing(baseSpace, 0.75), // 12
  base: scaleSpacing(baseSpace, 1), // 16
  m: scaleSpacing(baseSpace, 1.5), // 24
  l: scaleSpacing(baseSpace, 2), // 32
  xl: scaleSpacing(baseSpace, 2.5), // 40
  xxl: scaleSpacing(baseSpace, 3), // 48
  // allow the special keyword "auto" to work for padding/margin props
  auto: "auto",
  // add negative spaces, to allow for negative spaces for padding/margin props
  _xxs: scaleSpacing(baseSpace, -0.25), // 4
  _xs: scaleSpacing(baseSpace, -0.5), // 8
  _s: scaleSpacing(baseSpace, -0.75), // 12
  _base: scaleSpacing(baseSpace, -1), // 16
  _m: scaleSpacing(baseSpace, -1.5), // 24
  _l: scaleSpacing(baseSpace, -2), // 32
  _xl: scaleSpacing(baseSpace, -2.5), // 40
  _xxl: scaleSpacing(baseSpace, -3), // 48
};

const fontFamily = {
  base: {
    // font import done in header; breaks in GlobalStyles
    // url:
    //   "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap",
    name: "'Roboto', sans-serif",
  },
};

const fontSizes = {
  xs: "11px", // used in footer, tooltips, datepicker day
  s: "12px",
  base: "14px",
  m: "16px",
  l: "18px",
  // headings
  h1: "21px",
  h2: "16px",
  h3: "16px",
  h4: "14px",
};

const fontWeights = {
  normal: 400,
  semibold: 500,
  bold: 500,
  headings: 500,
  h3: 400,
};

const borderRadius = {
  none: "0px",
  xs: "2px", // tooltip
  s: "4px", // badge small
  base: "6px",
  m: "8px", // modals, popovers, cards
};

const iconSizes = {
  xxs: "10px",
  xs: "12px",
  s: "14px",
  base: "16px",
  m: "18px",
  inherit: "inherit",
};

const icons = {
  select: "TriangleDown",
};

const fields = {
  // font size
  fontSize: fontSizes.base,
  // line height
  lineHeight: 1.6,
  // Padding horizontal inside an input / button
  pH: spaces.s,
  // Padding vertical inside the input / button
  pV: spaces.xs,
  // Vertical space between input & label/ error/info
  gap: spaces.xs,
  // Default margin bottom after an input field
  mB: spaces.m,
  // the default height for inputs & buttons
  // we have a calculated value for easy use and correct alignment
  // but the value should be adjusted based on the values defined above:
  // fontSize * lineHeight + (2 * pV) + border
  // 14 * 1.6 + 2 * 8 + 2 ~= 40 ( rounded by browser as well)
  height: "40px",
  // The space left on the right / left side of an input with icon
  iconSpace: "32px",
  // the default look for inputs
  inputVariant: "filled",
  // the default look for search field
  searchVariant: "border",
  // size for radio buttons and checkboxes
  // it's also used for their label's line-heights
  radioCheckIconSize: iconSizes.base,
  // The size of the border radius for the check mark
  checkBorderRadius: borderRadius.xs,
  // the location of the label
  labelAbove: false,
};

const lineHeights = {
  base: 1.3,
  headings: 1.2,
  // labels: 1.4,
  labelsPx: "14px", // value used to avoid weird pixel rounding when vertically aligning something with an input
  fieldsHeight: fields.height, // value used to vertically align a text to an input; needed for visual alignment when items are not in the same container, spares us from adding extra containers / components
};

const menu = {
  pH: spaces.base, // for items
  pV: spaces.base, // for items
  mH: spaces.xs,
  mV: spaces.xxs,
  fontSize: fontSizes.base,
  logo: "26px",
  openWidth: "280px",
  iconSize: "14px",
};

const buttons = {
  // Prop defaults that apply to all button types.
  minW: "110px",
  textTransform: "none",
  disableOpacity: "0.5",
  // PrimaryButton prop defaults.
  primary: {
    color: "primary",
  },
  // SecondaryButton prop defaults. SecondaryButton will be placed next to a
  // PrimaryButton, usually for a cancel action.
  secondary: {
    variant: "filled",
    color: "border",
    textColor: "text",
  },
  // TertiaryButton prop defaults.
  tertiary: {
    variant: "border",
  },
  // DangerButton prop defaults. Usually for a delete action.
  danger: {
    variant: "filled",
    color: "error_1",
    textColor: "error",
  },
  // GhostButton prop defaults.
  // Will usually have just the text and no horizontal padding
  ghost: {
    variant: "transparent",
    color: "focus",
    pH: "none",
  },
  // icons buttons inherit the styles from the buttons with the same style and will
  // add specific settings defined for each
  iconPrimary: {
    shape: "round",
    w: "fieldsHeight",
  },
  iconSecondary: {
    w: "fieldsHeight",
  },
  // icon ghost button DOES NOT inherit styles from ghost button
  // because there was an issue with setting `p` for paddings, since pH is more specific
  iconGhost: {
    variant: "transparent",
    p: "none",
    color: "text",
  },
};

const toggles = {
  size: "default",
  colorOn: "focus",
  colorOff: "inputBorder",
};

const tabs = {
  color: "text",
  hoverColor: "focus",
  disabledColor: "textSecondary",
  variant: "narrow",
  // horizontal padding around each tab button
  // if the tabs are narrow they will be aligned using layouts.p - tabs.ph
  pH: spaces.s,
  fontSize: "14px",
};

const layouts = {
  // default padding for content in layout, cards, tabs, etc.
  p: spaces.m,
  pH: spaces.none,
  backgroundColor: "backgroundPrimary",
};

const zIndex = {
  above: 1,
  below: -1,
  drawer: 900,
  modalClose: 10,
  fixedTableHead: 10,
  dropdown: 20,
  tooltip: 500,
  notifications: 600,
  modal: 2000,
  popover: 3000,
};

/**
 * Palette composition: colorName: the main color shade colorName_1 ->
 * colorName_4 : shades for the color 1 is the lightest, 4 the darkest 1 & 2 are
 * lighter shades 3 & 4 darker shades the base colorName shade would be in the
 * middle.
 *
 * The exception are the "dark" and "light" color neutrals for these 2 palettes
 * the base color falls at the end of the spectrum.
 *
 * It is recommended that dark text is used over shades 1 & 2
 * and light text over shades 3 & 4.
 */
const palette = {
  dark: "#222222", //   (HS)L 13.3 (darkest)
  dark_1: "#909090", // (HS)L 56.5
  dark_2: "#646464", // (HS)L 39.2
  dark_3: "#474747", // (HS)L 27.8
  dark_4: "#323232", // (HS)L 19.6

  light: "#FFFFFF", //   (HS)L 100 (lightest)
  light_1: "#FAFAFA", // (HS)L 98
  light_2: "#EDEDED", // (HS)L 92.9
  light_3: "#DEDEDE", // (HS)L 87.1
  light_4: "#BCBCBC", // (HS)L 73.7

  green: "#14AD7D",
  green_1: "#D0EFE5", // shade lightest

  yellow: "#FFB12A",
  yellow_1: "#FFF7E9", // shade lightest

  red: "#F44B16",
  red_1: "#FDDBD0", // shade lightest
};

/**
 * Include "transparent", "inherit"  and "currentColor" so they are easier to
 * pass as options.
 *
 * Base Tokens must be extended in each theme
 */
export const baseColorTokens = {
  transparent: "transparent",
  inherit: "inherit",
  currentColor: "currentColor",

  primary: palette.green,
  primary_1: palette.green_1,

  /* messages */
  success: palette.green,
  success_1: palette.green_1,

  warning: palette.yellow,
  warning_1: palette.yellow_1,

  error: palette.red,
  error_1: palette.red_1,

  /** TODO: re-evaluate ui-tokens together with design team */
  /** UI tokens */
  // borders used generally in the ui
  border: palette.light_2,

  // the most predominant background color: page, card, tab panel
  backgroundPrimary: palette.light,
  // hover color for rows of backgroundPrimary, side tabs buttons, inline notifications
  backgroundSecondary: palette.light_1,
  // alternate rows, footer background
  backgroundTertiary: palette.light_2,
  // tags background, hover over row with backgroundTertiary
  backgroundFourth: palette.light_3,

  // the base text color
  text: palette.dark,
  // text in disabled inputs, timestamp in List
  textSecondary: palette.dark_1,
  // disabled tabs, disabled text
  textTertiary: palette.light_4,
  // text over dark backgrounds
  textInverted: palette.light,

  // color of labels; not textSecondary because in other themes it might have
  // another color
  label: palette.dark,

  focus: palette.green,

  inputBackground: palette.light_2,
  inputButton: palette.dark,
  inputIcon: palette.dark_1,
  inputBorder: palette.light_4,

  // some components will have different defaults in one theme or another - so
  // they need specific colors
  radioCheckBackground: palette.green,
  radioCheckInactive: palette.light_4,

  tableHeadText: palette.dark_2,

  modalOverlay: "rgba(0, 0, 0, 0.3)",

  // Menu
  // Each theme will implement wither Menu V1 or V2
  // Here we set defaults for both
  menuBackground: palette.light,
  menuIconActive: palette.green,
  //Menu v1
  menuIcon: palette.dark_2,
  menuText: palette.dark_1,
  menuTextActive: palette.dark,
  menuSeparator: palette.light_2, // same as border
  //Menu v2
  menuSearchBackground: "#083b41", // it's ok for this color to be custom
  menuSearchText: palette.light,
  menuItem: palette.light,
  menuBackgroundActive: "#083b41",
};

const theme = {
  spaces,
  fontFamily,
  fontSizes,
  fontWeights,
  lineHeights,
  borderRadius,
  iconSizes,
  icons,
  fields,
  menu,
  buttons,
  toggles,
  tabs,
  layouts,
  zIndex,
  colors: {
    ...baseColorTokens,
  },
  boxShadow: {
    dropdown: `0 12px 24px -8px rgba(0,0,0,0.08)`,
    popup: `0 10px 24px -8px rgba(0,0,0,0.2)`,
    modal: `0 14px 34px 0 rgba(0,0,0,0.2)`,
    card: `0 0 6px 0 rgba(0,0,0,0.1)`,
    drawer: `0 3px 8px 0 rgba(0,0,0,0.15)`,
    notification: `0 4px 20px 0 rgba(0,0,0,0.1)`,
    button: "0 2px 4px 0 rgba(0,0,0,0.1)",
    buttonHover: "0 2px 4px 0 rgba(0,0,0,0.2)",
    row: `0 2px 6px 2px rgba(0,0,0,0.05)`,
  },
  drawerHeaderHeight: "46px",
};

// TODO: Deal with PropTypes
// Use strings as a PropType instead of `oneOf` and add comments

/* Export a list of valid options for PropTypes ******************************************* */
/**
 * Since a lot of the project specific colors will be in the project's theme we
 * have given up exporting the types and using just those as valid PropTypes
 * export const colorTypes = Object.keys(colors);
 */
export const spaceTypes = Object.keys(spaces);

export default theme;
