import React from "react";
import { Box, Icon, Text } from "components";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

export const NO_MATCH_MESSAGE_HEIGHT = 200;

const NoMatchMessage = ({ verticalOffset }) => {
  const { t } = useTranslation();
  const { zIndex } = useTheme();
  return (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      direction="column"
      gap="m"
      mT="m"
      mB="m"
      mH="m"
      position="absolute"
      top={`${verticalOffset}px`}
      style={{ zIndex: zIndex.above }}
    >
      <Icon size="xl" name="Search"></Icon>
      <Text pH="m" textAlign="center">
        {/* TODO This text will remain in the middle in arabic ? */}
        {t("flights_list.noMatch")}
      </Text>
    </Box>
  );
};

export default NoMatchMessage;
