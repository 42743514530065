export const getPlaneIconPositionCSS = (type, status) => {
  let planeIconPositionCSS = "50%"; //middle

  if (
    (type === "departure" && status !== "Departed") ||
    (type === "arrival" && status === "Cancelled") ||
    (type === "arrival" && status === "Expected") ||
    (type === "arrival" && status === "Delayed")
  ) {
    planeIconPositionCSS = "10px"; //left
  }

  if (type === "arrival" && (status === "Landed" || status === "Arrived")) {
    planeIconPositionCSS = "calc(100% - 10px)"; //right
  }

  return planeIconPositionCSS;
};
