import React, { useEffect, useState } from "react";
import { hook } from "@airportlabs/automation-hooks";
import { listBareMixin } from "@airportlabs/smalt/src/mixins";
import PortalContent from "@airportlabs/smalt/src/Portal/PortalContent";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import styled, { css, useTheme } from "styled-components";

const propTypes = {
  /**
   * Specify first item that is active, by passing in the value of the tab
   * Will default to first tab
   * Is ignored when activeTab is passed in
   */
  isArabicLayout: PropTypes.bool,
  /**
   * Specify first item that is active, by passing in the value of the tab
   * Will default to first tab
   * Is ignored when activeTab is passed in
   */
  initialActive: PropTypes.string,
  /**
   * Function to execute when a tab is clicked
   * Function receives the tab value or index (when there isn't a value prop passed to the tab)
   * Required if you are using controlled tabs
   */
  onTabClick: PropTypes.func,
  /** Gives parent component control over which tab is active
   * Passing this prop will transform the Tabs into a *Controlled* component
   * Make sure you pass in "onTabClick"
   * Will be used instead of initialActive
   */
  activeTab: PropTypes.string,
  /** When `true` add in panels that change based on the currently active tab
   * @default: true
   */
  withPanels: PropTypes.bool,
  /** Child tabs */
  children: PropTypes.any,
  /** When `true` only current tab is rendered
   * @default: false
   */
  onlyRenderActive: PropTypes.bool,
  /**
   * When an `id` is passed, the tabs content will be rendered with Portal in a div that has this id;
   * Do not forget to add the container in the page, where you whish to display the content
   */
  portalInContainerWithID: PropTypes.string,
  /** Styles variants for the tabs  */
  positioning: PropTypes.oneOf(["top", "left", "topFixed"]),
  /** Styles variants for the tabs  */
  variant: PropTypes.oneOf(["normal", "narrow"]),
  /** Hook used for automation testing */
  automationHook: PropTypes.string,
};

/* Styled components ************************************ */

const TabsList = styled.ul`
  ${listBareMixin};
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  flex-wrap: wrap;

  /* TODO OVERWRITTEN TABS COMPONENT FOR ARABIC LAYOUT */
  justify-content: ${(props) =>
    props.isArabicLayout ? "flex-end" : undefined};

  ${(props) =>
    props.variant === "narrow" &&
    css`
      padding-left: calc(
        ${(props) => props.theme.layouts.p} - ${(props) => props.theme.tabs.pH}
      );
      padding-right: calc(
        ${(props) => props.theme.layouts.p} - ${(props) => props.theme.tabs.pH}
      );
    `}

  ${(props) =>
    props.positioning === "left" &&
    css`
      flex-shrink: 0;
      flex-direction: column;
      background-color: ${props.theme.colors.backgroundSecondary};
      padding-left: 0;
      padding-right: 0;
      align-items: flex-start;
      width: ${props.width || "200px"};
      height: 100%;
      position: relative;
      border-bottom: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: ${props.theme.colors.border};
      }
    `};
`;
TabsList.displayName = "TabsList_SC";

const TabButton = styled.li`
  display: flex;
  font-size: ${(props) => props.theme.tabs.fontSize};
  padding: ${(props) => props.theme.spaces.base}
    ${(props) => props.theme.tabs.pH};
  color: ${(props) => props.theme.colors[props.theme.tabs.color]};
  cursor: pointer;
  position: relative;

  &:focus,
  &:hover {
    outline: none;
    color: ${(props) => props.theme.colors[props.theme.tabs.hoverColor]};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${props.theme.colors[props.theme.tabs.disabledColor]};
      &:focus,
      &:hover {
        color: ${props.theme.colors[props.theme.tabs.disabledColor]};
      }
    `};

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.theme.colors[props.theme.tabs.activeColor]};
      font-weight: ${props.theme.fontWeights.semibold};

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: ${props.theme.zIndex.above};
        background-color: ${props.theme.colors.focus};
      }
    `};

  ${(props) =>
    props.variant === "narrow" &&
    css`
      ${props.isActive &&
      css`
        &::after {
          width: calc(100% - ${props.theme.tabs.pH} * 2);
          left: ${props.theme.tabs.pH};
        }
      `}
    `}

  ${(props) =>
    props.positioning === "left" &&
    css`
      padding: ${props.theme.spaces.base};
      background-color: transparent;
      width: 100%;

      ${props.isActive &&
      css`
        box-shadow: none;
        background-color: ${props.theme.colors.backgroundPrimary};
        border-right: 1px solid ${props.theme.colors.focus};

        &::after {
          display: none;
        }
      `}
    `}
`;

const PanelsWrapper = styled.div`
  width: 100%;

  ${(props) =>
    (props.positioning === "left" || props.positioning === "topFixed") &&
    css`
      overflow-y: auto;
    `}
`;
PanelsWrapper.displayName = "PanelsWrapper_SC";

/** Wrapper for the entire component: TabsList & Panels  */
const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.positioning === "left" &&
    css`
      flex-direction: row;
    `}

  ${(props) =>
    (props.positioning === "left" || props.positioning === "topFixed") &&
    css`
      height: 100%;
      overflow: hidden;
    `}
`;
TabsWrapper.displayName = "TabsWrapper_SC";

/* Tabs Component ************************************ */

const Tabs = ({
  initialActive,
  onTabClick,
  activeTab,
  withPanels = true,
  children,
  positioning = "top",
  variant,
  onlyRenderActive = false,
  portalInContainerWithID,
  automationHook,
  isArabicLayout = false,
}) => {
  const theme = useTheme();
  variant = variant || theme.tabs.variant;
  const [active, setActive] = useState(activeTab || initialActive);

  const isControlled = typeof activeTab !== "undefined";

  const WrapperInner = portalInContainerWithID ? PortalContent : PanelsWrapper;
  const wrapperProps = portalInContainerWithID
    ? { containerId: portalInContainerWithID }
    : {};

  const isActiveTab = (value, index) => {
    if (typeof active !== "undefined") {
      return active === value;
    }
    return index === 0;
  };

  // update the internal state when the activeTab changes
  useEffect(() => {
    if (typeof activeTab !== "undefined") {
      setActive(activeTab);
    }
  }, [activeTab]);

  return (
    children && (
      <TabsWrapper positioning={positioning}>
        <TabsList
          isArabicLayout={isArabicLayout}
          positioning={positioning}
          variant={variant}
          {...hook(automationHook, "tabs-list")}
        >
          {React.Children.map(children, (child, index) => {
            if (!child) {
              // allow null children
              return null;
            }

            const value = child.props.value || index;
            const onClickProp = child.props.disabled
              ? {}
              : {
                  onClick: (event) => {
                    event.preventDefault();
                    if (!isControlled) {
                      setActive(value);
                    }
                    if (typeof onTabClick === "function") {
                      onTabClick(value);
                    }
                  },
                };
            return (
              <TabButton
                key={index}
                isActive={isActiveTab(value, index)}
                {...onClickProp}
                disabled={child.props.disabled}
                variant={variant}
                positioning={positioning}
                {...hook(
                  automationHook,
                  `${
                    child.props.automationName
                      ? child.props.automationName
                      : kebabCase(value)
                  }-tab${isActiveTab(value, index) ? "-active" : ""}`
                )}
              >
                {child.props.title}
              </TabButton>
            );
          })}
        </TabsList>

        {withPanels && (
          <WrapperInner
            {...wrapperProps}
            variant={variant}
            positioning={positioning}
            {...hook(automationHook, "tabs-content")}
          >
            {React.Children.map(children, (child, index) => {
              if (!child) {
                // allow null children
                return null;
              }

              const isActive = isActiveTab(child.props.value, index);
              const value = child.props.value || index;

              if (!onlyRenderActive || (onlyRenderActive && isActive)) {
                return React.cloneElement(child, {
                  key: index,
                  isActive,
                  ...hook(
                    automationHook,
                    `${
                      child.props.automationName
                        ? child.props.automationName
                        : kebabCase(value)
                    }-content`
                  ),
                  title: undefined,
                });
              }
              return null;
            })}
          </WrapperInner>
        )}
      </TabsWrapper>
    )
  );
};
Tabs.propTypes = propTypes;
Tabs.displayName = "Tabs";

/* Exports ****************************************** */
export default Tabs;
