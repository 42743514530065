export const extractFlightNumber = (decodedText) => {
  //Finds the first group of 4 digits sequence
  const regex = /\b(\d{4})\b/;
  const match = decodedText.match(regex);

  if (match) {
    const flightNumber = match[0].trim();
    const flightNumberShortened = removeLeadingZero(flightNumber); //Pocket Flights does not display flights with two leading zeros

    const airlineCode = decodedText.split(flightNumber)[0].trim().slice(-2); //Extracts Airline IATA code just before the 4 digits sequence

    return `${airlineCode}${flightNumberShortened}`;
  }
};

export const removeLeadingZero = (inputString) => {
  if (inputString.startsWith("0") && inputString.length >= 1) {
    return inputString.slice(1);
  }

  return inputString;
};
