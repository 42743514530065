import { Box, Text } from "components";
import Toggle from "components/smaltOverwritten/Toggle";
import { NOTIFICATION_CASES } from "configs/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notificationsSelector } from "redux/slices/notifications.slice";
import {
  subscribeToNotificationsServer,
  unsubscribeNotificationForFlight,
} from "services/notifications.service";
import styled from "styled-components";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const NotificationsContentStyled = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spaces.s};
  flex-direction: column;
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const NotificationInstructions = ({
  flightId,
  handleOpenNotificationsModal,
  notificationType,
}) => {
  const { t, i18n } = useTranslation();
  const { notificationFlights } = useSelector(notificationsSelector);

  const hasNotificationsEnabledForFlight =
    notificationFlights.includes(flightId);

  let informationText = "";
  let toggleDisabled = false;

  switch (notificationType) {
    case NOTIFICATION_CASES.IOS_VERSION_BELOW_16_4:
      informationText = t(
        "notifications_instructions.informationText_iOSVersionBelow16_4"
      );

      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.DEFAULT_BROWSER_NOT_SAFARI:
      informationText = t(
        "notifications_instructions.informationText_defaultBrowserNotSafari"
      );
      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.APP_NOT_INSTALLED_AS_PWA:
      informationText = t(
        "notifications_instructions.informationText_appNotInstalledAsPWA"
      );
      toggleDisabled = true;
      break;
    case NOTIFICATION_CASES.IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA:
    case NOTIFICATION_CASES.USER_IS_NOT_BROWSING_MOBILE:
      informationText = t(
        "notifications_instructions.informationText_userIsNotBrowsingMobile"
      );
      break;
    default:
      break;
  }

  const handleToggleChange = () => {
    if (hasNotificationsEnabledForFlight) {
      unsubscribeNotificationForFlight(flightId);
    } else {
      subscribeToNotificationsServer(flightId);
    }
  };

  const shouldReverseOrder = isArabic(i18n);

  return (
    <NotificationsContentStyled>
      <Box
        d="flex"
        justifyContent="space-between"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <Text fontSize="m" color="otherText" style={getTranslationsStyle(i18n)}>
          {t("notifications_instructions.receiveNotifications")}
        </Text>
        <Toggle
          onChange={
            toggleDisabled
              ? () => handleOpenNotificationsModal(notificationType)
              : handleToggleChange
          }
          checked={hasNotificationsEnabledForFlight}
          name="push-notifications"
          onClick={
            toggleDisabled
              ? () => handleOpenNotificationsModal(notificationType)
              : null
          }
          size="big"
          borderColor="borderBottom"
        />
      </Box>
      <Box>
        <Text color="textSecondary" style={getTranslationsStyle(i18n)}>
          {informationText}
          &nbsp;
          <Text
            onClick={() => handleOpenNotificationsModal(notificationType)}
            color="primary"
            as="span"
          >
            {t("notifications_instructions.learnMore")}
          </Text>
        </Text>
      </Box>
    </NotificationsContentStyled>
  );
};

export default NotificationInstructions;
