import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const initializeSlice = createSlice({
  name: "initialize",
  initialState,
  reducers: {
    setInitialData: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { setInitialData } = initializeSlice.actions;

export const InitializeSelector = (state) => state.initialize;

export default initializeSlice.reducer;
