import { css } from "styled-components";

// eslint-disable-next-line import/no-cycle
import { buttonTotalResetMixin } from "./Buttons/ButtonStyled";
import {
  flexContainerMixin,
  getMarginFromProps,
  getMarginPropValue,
  getPaddingFromProps,
  getPaddingPropValue,
  marginMixin,
  marginMixinPropTypes,
  noMarginLastChild,
  padMyChildrenMixin,
  paddingMixin,
  paddingMixinPropTypes,
} from "./Containers/mixins";
import {
  fontWeightMixin,
  hiddenButReaderAccessibleMixin,
  textAlignMixin,
  truncateChildrenMixin,
  truncateMixin,
} from "./Typography/mixins";
import { getColor } from "./helpers";
import { listBareMixin, separatorMixin } from "./Lists/mixins";

const backgroundMixin = (fallbackColorName = "transparent") => css`
  background-color: ${(props) =>
    getColor(props, "backgroundColor", fallbackColorName)};
`;

const colorMixin = (fallbackColorName = "inherit") => css`
  color: ${(props) => getColor(props, "color", fallbackColorName)};
`;

const hTagMixin = (tag) => css`
  font-size: ${(props) =>
    props.theme.fontSizes[tag] || props.theme.fontSizes.headings};
  font-weight: ${(props) =>
    props.theme.fontWeights[tag] || props.theme.fontWeights.headings};
  line-height: ${(props) =>
    props.theme.lineHeights[tag] || props.theme.lineHeights.headings};
`;

const gluedMixin = () => css`
  ${(props) =>
    props.glued &&
    css`
      ${props.glued === "left" &&
      css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      `}

      ${props.glued === "right" &&
      css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      `}

      ${props.glued === "horizontal" &&
      css`
        border-radius: 0;
        border-left: 0;
        border-right: 0;
      `}
    `}
`;

export {
  backgroundMixin,
  colorMixin,
  gluedMixin,
  /* Button */
  buttonTotalResetMixin,
  /* Spacing / Containers */
  paddingMixin,
  paddingMixinPropTypes,
  padMyChildrenMixin,
  marginMixin,
  marginMixinPropTypes,
  noMarginLastChild,
  flexContainerMixin,
  getPaddingPropValue,
  getMarginPropValue,
  getPaddingFromProps,
  getMarginFromProps,
  /* List */
  listBareMixin,
  separatorMixin,
  /* Text */
  textAlignMixin,
  fontWeightMixin,
  hiddenButReaderAccessibleMixin,
  hTagMixin,
  truncateMixin,
  truncateChildrenMixin,
};
