import React from "react";
import { Box, Text } from "components";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle } from "utils/arabisation.util";

const ViaInformation = ({ flights = [] }) => {
  const { t, i18n } = useTranslation();

  if (!flights.length) {
    return null;
  }

  return (
    <Box d="flex" direction="column" gap="xs">
      {flights.length === 1 ? (
        <Text
          color="textPrimary"
          fontWeight="semibold"
          fontSize="l"
          style={getTranslationsStyle(i18n)}
        >
          {t("flights_list.flightItem_content_Via")}
        </Text>
      ) : (
        <Text
          color="textPrimary"
          fontWeight="semibold"
          fontSize="l"
          style={getTranslationsStyle(i18n)}
        >
          {`${flights.length} ${t(
            "flights_list.flightItem_content_numberOfStops"
          )}`}
        </Text>
      )}
      <Box>
        {flights.map((viaFlight) => (
          <Text key={viaFlight.name} fontWeight="semibold">
            {viaFlight.name}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default ViaInformation;
