import React from "react";
import { Box, Text } from "components";
import { DEFAULT_REALM_CODE, DEFAULT_REALM_NAME } from "configs/constants";
import { useGetSettingsQuery } from "redux/queries/settings.query";

const shouldUseRealmInformation = (type, direction) => {
  return (
    (type === "arrival" && direction === "destination") ||
    (type === "departure" && direction === "source")
  );
};

const AirportDetails = ({ direction, type, airport }) => {
  const { data = {} } = useGetSettingsQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: true,
  });

  const { realmName = DEFAULT_REALM_NAME, realmCode = DEFAULT_REALM_CODE } =
    data;

  const getAirportInformation = () => {
    if (shouldUseRealmInformation(type, direction)) {
      return {
        name: realmName,
        code: realmCode,
      };
    }

    return {
      name: airport?.name || "",
      code: airport?.code || "",
    };
  };

  const { code, name } = getAirportInformation();

  return (
    <Box d="flex" direction="column" flex="1" gap="xs">
      <Text
        color="textPrimary"
        fontWeight="semibold"
        fontSize="l"
        textAlign={direction === "destination" ? "end" : null}
      >
        {code}
      </Text>
      <Text
        fontWeight="bold"
        textAlign={direction === "destination" ? "end" : null}
      >
        {name}
      </Text>
    </Box>
  );
};

export default AirportDetails;
