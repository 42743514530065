import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

export const languageDrawerSlice = createSlice({
  name: "languageDrawer",
  initialState,
  reducers: {
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});

export const { setIsOpen } = languageDrawerSlice.actions;

export const languageDrawerSelector = (state) => state.languageDrawer;

export default languageDrawerSlice.reducer;
