import {
  ACTIVE_TAB,
  FLIGHT_TYPE,
  START_SEARCH_CONTAIN_LENGTH,
} from "configs/constants";

const normalizeString = (str) => str.toUpperCase().split(" ").join("");

const matchFlightDetails = (keyword, flight) => {
  const flightNumber = normalizeString(flight.flight_no);
  const airportCode = normalizeString(flight.airport.code);
  const flightDestination = normalizeString(
    flight.type === FLIGHT_TYPE.DEPARTURE ? flight.destination : flight.origin
  );
  const airline = flight.airline?.name
    ? normalizeString(flight.airline.name)
    : "";
  const viaKeywords = flight.via_keywords
    ? normalizeString(flight.via_keywords)
    : "";

  return (
    flightNumber.startsWith(keyword) ||
    flightDestination.startsWith(keyword) ||
    airportCode.startsWith(keyword) ||
    airline.startsWith(keyword) ||
    viaKeywords.includes(keyword)
  );
};

export const filterFlightsBySearch = (searchKeyword, allFlights, options) => {
  const { flights, favoriteFlights } = allFlights;
  const {
    activeTab,
    terminalToFilter,
    startSearchContainLength = START_SEARCH_CONTAIN_LENGTH,
  } = options;

  const keyword = normalizeString(searchKeyword);

  if (activeTab === ACTIVE_TAB.FAVORITE && favoriteFlights?.length) {
    return favoriteFlights;
  }

  const preliminaryResult = flights.filter((flight) => {
    if (activeTab !== ACTIVE_TAB.ALL && flight.type !== activeTab) {
      return false;
    }

    if (keyword.length <= startSearchContainLength) {
      return (
        matchFlightDetails(keyword, flight) &&
        (flight.terminal_name === terminalToFilter || terminalToFilter === null)
      );
    }

    const searchChars = keyword.split("");
    // Store how many times the search digit is found in the flightNumber
    let history = {};

    const foundCharsInFlightNumber = searchChars.every((char) => {
      if (!history[char]) {
        history[char] = 1;
      } else {
        history[char] += 1;
      }

      const flightNumber = normalizeString(flight.flight_no);
      const countInFlightNumber = (
        flightNumber.match(new RegExp(char, "g")) || []
      ).length;
      return countInFlightNumber >= history[char];
    });

    return foundCharsInFlightNumber;
  });

  // Find an exact match for the flight number
  const exactMatch = preliminaryResult.find(
    (flight) => normalizeString(flight.flight_no) === keyword
  );

  return exactMatch ? [exactMatch] : preliminaryResult;
};
