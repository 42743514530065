import React, { useCallback, useMemo } from "react";
import { Search } from "components";
import Dropdown from "components/Reusable/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  flightsListSelector,
  setSearchKeyword,
  triggerScrollToTop,
} from "redux/slices/flightsList.slice";
import styled, { useTheme } from "styled-components";
import { isArabic } from "utils/arabisation.util";

import { FLIGHTS_COUNT_HEIGHT } from "../FlightsCount";
import { FLIGHT_TABS_HEIGHT } from "./FlightTabs";

const SEARCH_BOX_HEIGHT = 88;

const SearchBox = ({
  terminal,
  terminalsOptionList,
  setQueryParams,
  isFlightCountVisible,
  backToTopOffset,
}) => {
  const { data: settingsData = {} } = useGetSettingsQuery();
  const isTerminalFilteringActive = settingsData.terminalFilter !== "disabled";

  const currentUrlTerminal = terminalsOptionList.find((apiTerminal) => {
    return apiTerminal.value === terminal;
  });

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchKeyword } = useSelector(flightsListSelector);

  const handleSearch = (keyword) => {
    dispatch(setSearchKeyword(keyword));
  };

  const TerminalDropdownOptionList = useMemo(
    () => [
      {
        full_name: t("header.dropdown_allTerminals"),
        short_name: t("header.dropdown_all"),
        value: null,
      },
      ...terminalsOptionList,
    ],
    [t, terminalsOptionList]
  );

  const onDropdownChange = useCallback(
    (name, option) => {
      if (!option.value) {
        setQueryParams();
        return;
      }
      setQueryParams({ terminal: option.value });
      dispatch(triggerScrollToTop(backToTopOffset));
    },
    [dispatch, setQueryParams, backToTopOffset]
  );

  const shouldReverseOrder = isArabic(i18n);

  return (
    <SearchBoxStyled
      isFlightCountVisible={isFlightCountVisible}
      shouldReverseOrder={shouldReverseOrder}
    >
      {/* TODO Fix Search icon positioning when arabic language is active */}
      <Search
        onSearch={(value) => {
          handleSearch(value);
        }}
        placeholder={t("header.searchBoxPlaceholder")}
        autoComplete="off"
        searchOnChange
        debounce={200}
        backgroundColor="backgroundTertiary"
        value={searchKeyword}
        style={{ direction: shouldReverseOrder ? "rtl" : undefined }}
      />
      {isTerminalFilteringActive && (
        <Dropdown
          displayProperty="full_name"
          displayShortProperty="short_name"
          optionList={TerminalDropdownOptionList}
          initialValue={currentUrlTerminal || TerminalDropdownOptionList[0]}
          w={theme.dropdown.dropdownWidth}
          setOnChange={onDropdownChange}
          menuPosition={shouldReverseOrder ? "left" : undefined}
        />
      )}
    </SearchBoxStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const SearchBoxStyled = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.shouldReverseOrder ? "row-reverse" : undefined};
  gap: ${(props) => props.theme.spaces.s};
  background-color: ${(props) => props.theme.colors.textQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  padding: ${(props) => props.theme.spaces.m} ${(props) => props.theme.spaces.m};

  left: ${(props) => props.theme.spaces.m};
  width: calc(100vw - ${(props) => props.theme.spaces.xxl});

  top: calc(
    100% -
      ${(props) =>
        props.isFlightCountVisible
          ? FLIGHT_TABS_HEIGHT + SEARCH_BOX_HEIGHT / 2 + FLIGHTS_COUNT_HEIGHT
          : FLIGHT_TABS_HEIGHT + SEARCH_BOX_HEIGHT / 2}px
  );
`;

export default SearchBox;
