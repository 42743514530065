import PropTypes from "prop-types";
import { css } from "styled-components";

import { getSpace } from "../helpers";
import { spaceTypes } from "../themes/_base";

export const paddingMixinPropTypes = {
  /* String for all paddings */
  p: PropTypes.oneOf(spaceTypes),
  /* Vertical paddings */
  pV: PropTypes.oneOf(spaceTypes),
  /* Horizontal paddings */
  pH: PropTypes.oneOf(spaceTypes),
  /*  Padding Top */
  pT: PropTypes.oneOf(spaceTypes),
  /*  Padding Bottom */
  pB: PropTypes.oneOf(spaceTypes),
  /*  Padding Right */
  pR: PropTypes.oneOf(spaceTypes),
  /*  Padding Left */
  pL: PropTypes.oneOf(spaceTypes),
};

export const paddingMixin = () => css`
  ${(props) =>
    props.p &&
    css`
      padding: ${props.theme.spaces[props.p]};
    `}

  ${(props) =>
    props.pV &&
    css`
      padding-top: ${props.theme.spaces[props.pV]};
      padding-bottom: ${props.theme.spaces[props.pV]};
    `}

    ${(props) =>
    props.pH &&
    css`
      padding-left: ${props.theme.spaces[props.pH]};
      padding-right: ${props.theme.spaces[props.pH]};
    `}

    ${(props) =>
    props.pT &&
    css`
      padding-top: ${props.theme.spaces[props.pT]};
    `}

    ${(props) =>
    props.pB &&
    css`
      padding-bottom: ${props.theme.spaces[props.pB]};
    `};

  ${(props) =>
    props.pR &&
    css`
      padding-right: ${props.theme.spaces[props.pR]};
    `};

  ${(props) =>
    props.pL &&
    css`
      padding-left: ${props.theme.spaces[props.pL]};
    `}
`;

export const padMyChildrenMixin = (child) => css`
  ${child} {
    padding: ${(props) => props.theme.spaces.base};
    ${paddingMixin};
  }
`;

export const marginMixinPropTypes = {
  /* String for all margins */
  m: PropTypes.oneOf(spaceTypes),
  /* Vertical margins */
  mV: PropTypes.oneOf(spaceTypes),
  /* Horizontal margins */
  mH: PropTypes.oneOf(spaceTypes),
  /*  Margin Top */
  mT: PropTypes.oneOf(spaceTypes),
  /*  Margin Bottom */
  mB: PropTypes.oneOf(spaceTypes),
  /*  Margin Right */
  mR: PropTypes.oneOf(spaceTypes),
  /*  Margin Left */
  mL: PropTypes.oneOf(spaceTypes),
};

export const marginMixin = () => css`
  ${(props) =>
    props.m &&
    css`
      margin: ${props.theme.spaces[props.m]};
    `}

  ${(props) =>
    props.mV &&
    css`
      margin-top: ${props.theme.spaces[props.mV]};
      margin-bottom: ${props.theme.spaces[props.mV]};
    `}

    ${(props) =>
    props.mH &&
    css`
      margin-left: ${props.theme.spaces[props.mH]};
      margin-right: ${props.theme.spaces[props.mH]};
    `}

    ${(props) =>
    props.mT &&
    css`
      margin-top: ${props.theme.spaces[props.mT]};
    `}

    ${(props) =>
    props.mB &&
    css`
      margin-bottom: ${props.theme.spaces[props.mB]};
    `}

    ${(props) =>
    props.mL &&
    css`
      margin-left: ${props.theme.spaces[props.mL]};
    `}

    ${(props) =>
    props.mR &&
    css`
      margin-right: ${props.theme.spaces[props.mR]};
    `}
`;

export const noMarginLastChild = (props) =>
  props.noMarginLastChild &&
  css`
    > *:last-child {
      margin-bottom: 0;
    }
  `;

export const flexContainerMixin = ({
  defaultAlignItems = "center",
  defaultJustifyContent = "center",
}) => css`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || defaultAlignItems};
  justify-content: ${(props) => props.justifyContent || defaultJustifyContent};

  ${(props) =>
    props.shrink &&
    css`
      flex-shrink: ${props.shrink === true ? "1" : props.shrink};
    `};

  ${(props) =>
    props.grow &&
    css`
      flex-grow: ${props.grow === true ? "1" : props.grow};
    `};

  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.gap &&
    css`
      gap: ${getSpace(props, "gap", "base")};
    `};

  /** allow user to pass in a different gap for rows (vertical) */
  ${(props) =>
    props.gapV &&
    css`
      row-gap: ${getSpace(props, "gapV", "base")};
    `};
`;

/** This function gets the correct padding in pixels for a side, given and object with padding props.
 *
 * @param {Object} props The props of the styled component. Are expected to contain the theme and one or more of the padding props: "p", "pV", "pH", "pL", "pR", "pT", "pB"
 * @param {String} side The side we are computing the padding for. One of "left", "right", "top", "bottom"
 * @param {String} fallback What value should be used as a fallback, in case there is no padding found in props for the specified side
 * @returns {String} the pixel value for the padding, taken from theme spaces or an empty string
 */
export const getPaddingPropValue = (props, side = "top", fallback) => {
  // get a padding prop value if one is passed in
  let padding = "";

  switch (side) {
    case "left":
      padding = props.pL || props.pH || props.p;
      break;
    case "right":
      padding = props.pR || props.pH || props.p;
      break;
    case "bottom":
      padding = props.pB || props.pV || props.p;
      break;
    case "top":
    default:
      padding = props.pT || props.pV || props.p;
  }

  return padding
    ? props.theme.spaces[padding]
    : fallback
    ? props.theme.spaces[fallback]
    : "";
};

/** This function gets the correct margin in pixels for a side, given and object with margin props.
 *
 * @param {Object} props The props of the styled component. Are expected to contain the theme and one or more of the margin props: "m", "mV", "mH", "mL", "mR", "mT", "mB"
 * @param {String} side The side we are computing the margin for. One of "left", "right", "top", "bottom"
 * @param {String} fallback What value should be used as a fallback, in case there is no margin found in props for the specified side
 * @returns {String} the pixel value for the margin, taken from theme spaces or an empty string
 */
export const getMarginPropValue = (props, side = "top", fallback) => {
  let margin = "";
  switch (side) {
    case "left":
      margin = props.mL || props.mH || props.m;
      break;
    case "right":
      margin = props.mR || props.mH || props.m;
      break;
    case "bottom":
      margin = props.mB || props.mV || props.m;
      break;
    case "top":
    default:
      margin = props.mT || props.mV || props.m;
  }

  return margin
    ? props.theme.spaces[margin]
    : fallback
    ? props.theme.spaces[fallback]
    : "";
};

/**
 * Function used to set the default paddings for an element, given an object of props.
 *
 * @param {Object} props only the theme is required from this object
 * @param {Object} propsObject an object that contains padding props that we want to use (p, pV, pH, etc.). For example this would be an object set in the theme like "layoutDefaults"
 * @returns {String} css for padding-top, padding-bottom, etc.
 */
export const getPaddingFromProps = (props, propsObject) => css`
  padding-top: ${getPaddingPropValue(
    { theme: props.theme, ...propsObject },
    "top"
  )};
  padding-left: ${getPaddingPropValue(
    { theme: props.theme, ...propsObject },
    "left"
  )};
  padding-right: ${getPaddingPropValue(
    { theme: props.theme, ...propsObject },
    "right"
  )};
  padding-bottom: ${getPaddingPropValue(
    { theme: props.theme, ...propsObject },
    "bottom"
  )};
`;

/**
 * Function used to set the default margins for an element, given an object of props.
 *
 * @param {Object} props only the theme is required from this object
 * @param {Object} propsObject an object that contains margin props that we want to use (p, pV, pH, etc.). For example this would be an object set in the theme like "layoutDefaults"
 * @returns {String} css for margin-top, margin-bottom, etc.
 */
export const getMarginFromProps = (props, propsObject) => css`
  margin-top: ${getMarginPropValue(
    { theme: props.theme, ...propsObject },
    "top"
  )};
  margin-left: ${getMarginPropValue(
    { theme: props.theme, ...propsObject },
    "left"
  )};
  margin-right: ${getMarginPropValue(
    { theme: props.theme, ...propsObject },
    "right"
  )};
  margin-bottom: ${getMarginPropValue(
    { theme: props.theme, ...propsObject },
    "bottom"
  )};
`;
