import React from "react";
import { Text } from "components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle } from "utils/arabisation.util";

const LastUpdate = ({ lastUpdated }) => {
  const { t, i18n } = useTranslation();
  const relativeTimeOfLastUpdate = DateTime.fromISO(lastUpdated)
    .setLocale(i18n.language)
    .toRelative();

  if (!relativeTimeOfLastUpdate) {
    return null;
  }

  return (
    <Text mB="l" color="textSecondary" style={getTranslationsStyle(i18n)}>
      {t("general_info.lastUpdate")}&nbsp;{relativeTimeOfLastUpdate}
    </Text>
  );
};

export default LastUpdate;
