import React from "react";
import styled from "styled-components";

import Item from "./menu/Item";

const MenuStyled = styled.div`
  position: absolute;
  right: ${(props) => (props.menuPosition === "right" ? 0 : undefined)};
  left: ${(props) => (props.menuPosition === "left" ? 0 : undefined)};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.backgroundTertiary};
  width: ${(props) => props.theme.dropdown.dropdownMenuWidth};
  margin-top: ${(props) => props.theme.spaces.xs};
  color: ${(props) => props.theme.colors.textTertiary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.dropdown};
  z-index: ${(props) => props.theme.zIndex.dropdown};
`;

const Menu = ({
  optionList,
  dropDownValue,
  isDropDownOpen,
  displayProperty,
  handleDropDownItemSelect,
  getDropdownValue,
  menuPosition,
}) => {
  return (
    <MenuStyled isDropDownOpen={isDropDownOpen} menuPosition={menuPosition}>
      {optionList?.map((option, index) => (
        <Item
          handleDropDownItemSelect={handleDropDownItemSelect}
          getDropdownValue={getDropdownValue}
          displayProperty={displayProperty}
          dropDownValue={dropDownValue}
          option={option}
          key={index}
        />
      ))}
    </MenuStyled>
  );
};

export default Menu;
