import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationFlights: [],
  hasNotificationsEnabled: false,
  subscription: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addFlight: (state, { payload }) => {
      state.notificationFlights = [...state.notificationFlights, payload];
    },
    removeFlight: (state, { payload }) => {
      state.notificationFlights = state.notificationFlights.filter(
        (flight) => flight !== payload
      );
    },
    setHasNotificationsEnabled: (state, { payload }) => {
      state.hasNotificationsEnabled = payload;
    },
    setSubscription: (state, { payload }) => {
      state.subscription = payload;
    },
  },
});

export const {
  addFlight,
  removeFlight,
  setHasNotificationsEnabled,
  setSubscription,
} = notificationsSlice.actions;

export const notificationsSelector = (state) => state.notifications;

export default notificationsSlice.reducer;
