/*
 *@prettier
 */
import { css } from "styled-components";

import { getColor } from "../helpers";
import { getPaddingPropValue } from "../mixins";

const line = (props) => css`
  content: "";
  display: block;
  width: calc(
    100% - ${getPaddingPropValue(props, "left", "base")} -
      ${getPaddingPropValue(props, "right", "base")}
  );
  position: absolute;
  left: ${getPaddingPropValue(props, "left", "base")};
  height: 1px;
  background-color: ${getColor(props, "separatorColor", "border")};

  ${props.separatorVariant === "full" &&
  css`
    width: 100%;
    left: 0;
  `}
`;

export const separatorMixin = (props, placement = "bottom") => css`
  position: relative;

  &::after {
    ${line(props)};
    ${placement}: 0;
  }
  ${placement === "both" &&
  css`
    &::after {
      top: 0;
    }
    &::before {
      ${line(props)};
      bottom: 0;
    }
  `}
`;

export const listBareMixin = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;
