export const formatQueryString = (queryData) => {
  //Function takes an object with key-value-pairs for query params and returns the stringified chained version
  const queryString = new URLSearchParams();

  for (let queryParameter in queryData) {
    const queryValue = queryData[queryParameter];
    if (queryParameter && queryValue) {
      if (Array.isArray(queryValue)) {
        queryValue.forEach((arrayElement) => {
          queryString.append(queryParameter, arrayElement);
        });
      } else {
        queryString.append(queryParameter, queryValue);
      }
    }
  }
  return `?${queryString.toString()}`;
};
