import React from "react";
import { ReactComponent as SuccessCheckmarkIcon } from "assets/images/success.svg";
import { Box, Text } from "components/smalt";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const Toast = ({ type, position, message }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const shouldReverseOrder = isArabic(i18n);
  return (
    <Box
      backgroundColor="successBackground"
      d="flex"
      alignItems="center"
      borderRadius="base"
      gap="xs"
      minH="44px"
      direction={shouldReverseOrder ? "row-reverse" : undefined}
    >
      <SuccessCheckmarkIcon
        style={{
          marginLeft: !shouldReverseOrder ? theme.spaces.s : undefined,
          marginRight: shouldReverseOrder ? theme.spaces.s : undefined,
        }}
      />
      <Text style={getTranslationsStyle(i18n)}>{message}</Text>
    </Box>
  );
};

export default Toast;
