import { useRef, useState } from "react";

const useSwipe = () => {
  const [translationAmount, setTranslationAmount] = useState(0);
  const touchStartY = useRef(0);
  const touchCurrentY = useRef(0);

  const handleTouchStart = (event) => {
    touchStartY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    touchCurrentY.current = event.touches[0].clientY;

    const delta = touchStartY.current - touchCurrentY.current;
    const newHeight = translationAmount + delta;
    setTranslationAmount(newHeight);
    touchStartY.current = touchCurrentY.current;
  };

  const handleTouchEnd = () => {
    const delta = touchStartY.current - touchCurrentY.current;
    const newHeight = translationAmount + delta;
    setTranslationAmount(newHeight);
    touchStartY.current = 0;
    touchCurrentY.current = 0;
  };

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    setTranslationAmount,
    translationAmount,
  };
};

export default useSwipe;
