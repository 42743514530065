import React from "react";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right.svg";
import { Box, Text } from "components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { isArabic } from "utils/arabisation.util";

import Status from "./header/Status";

const Header = ({ status, flightTime, delayedTime }) => {
  const { i18n } = useTranslation();
  const shouldReverseOrder = isArabic(i18n);
  return (
    <Box
      d="flex"
      m="s"
      pT="s"
      justifyContent="space-between"
      alignItems="center"
      direction={shouldReverseOrder ? "row-reverse" : undefined}
    >
      <Box d="flex">
        <Text
          mR="xs"
          fontSize="l"
          fontWeight="bold"
          textDecoration={delayedTime && "line-through"}
        >
          {DateTime.fromISO(flightTime).toFormat("HH:mm")}
        </Text>
        {delayedTime && (
          <Box d="flex" alignItems="center">
            <ArrowRight />
            <Text
              mL="xs"
              fontSize="l"
              fontWeight="bold"
              as="span"
              color="accent_1"
            >
              {DateTime.fromISO(delayedTime).toFormat("HH:mm")}
            </Text>
          </Box>
        )}
      </Box>
      {/* TODO Are the statuses translated from BE? */}
      <Status status={status} />
    </Box>
  );
};

export default Header;
