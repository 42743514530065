import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import {
  backgroundMixin,
  colorMixin,
  getPaddingPropValue,
  marginMixin,
  paddingMixin,
  paddingMixinPropTypes,
} from "../mixins";
import { spaceTypes } from "../themes/_base";
import { useDeprecate } from "../helpers";

/** TODO: maybe add margins API as well as the padding API? */

export const iconStylePropTypes = {
  /**
   * Color for the icon. Should be a valid color name from the theme. It will be set to "inherit" if it'is not passed
   */
  color: PropTypes.string,
  /**
   * Size of the icon as a string, should exist in the iconSizes object
   * @default "base"
   */
  size: PropTypes.string,
  /**
   * Number of degrees to rotate the icon by
   */
  rotate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * DEPRECATED: use padding props instead: "p", "pH", "pV", etc.
   */
  padding: PropTypes.oneOf(spaceTypes),
  /** Padding prop Types */
  ...paddingMixinPropTypes,
  /** DEPRECATED: Fixed width & height for the icon. Use "w" */
  width: PropTypes.string,
  /**
   * Fixed width & height for the icon. Careful it will include the padding
   */
  w: PropTypes.string,
  /**
   * Title for the svg, used by screen readers
   */
  title: PropTypes.string,
  /**
   * Specifies the background-color to be applied.
   * It should be a valid color token.
   */
  backgroundColor: PropTypes.string,
  /**
   * Specifies the border radius to be applied.
   */
  borderRadius: PropTypes.string,
};

const IconWrapperSC = styled.div`
  display: inline-flex;
  font-size: ${(props) =>
    props.theme.iconSizes[props.size] || props.theme.iconSizes.base};
  line-height: 1;
  justify-content: center;
  align-items: center;
  ${colorMixin("inherit")};
  ${backgroundMixin()};
  /* prevent icon from shrinking when inside a display: flex container */
  flex-shrink: 0;
  /** use padding props */
  ${paddingMixin};
  ${marginMixin};
  /** set width & height to take into account padding */
  width: calc(
    1em + ${(props) => getPaddingPropValue(props, "left") || "0px"} +
      ${(props) => getPaddingPropValue(props, "right") || "0px"}
  );
  height: calc(
    1em + ${(props) => getPaddingPropValue(props, "top") || "0px"} +
      ${(props) => getPaddingPropValue(props, "bottom") || "0px"}
  );

  svg {
    shape-rendering: inherit;
    fill: currentColor;
    /** prevent title tooltip from showing */
    pointer-events: none;
  }

  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `};

  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.theme.borderRadius[props.borderRadius] ??
      props.theme.borderRadius.base};
    `};

  /** Allow specific width */
  ${(props) =>
    props.w &&
    css`
      width: ${props.w};
      height: ${props.w};
    `};
`;
IconWrapperSC.displayName = "IconWrapperSC";

const IconWrapper = ({ width, padding, ...props }) => {
  useDeprecate({ width, padding }, "IconWrapper");

  return <IconWrapperSC w={width} p={padding} {...props} />;
};
IconWrapper.propTypes = iconStylePropTypes;

export default IconWrapper;
