import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getClientCredentials } from "services/auth.service";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: window._env_?.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      headers.set("Content-type", `application/json`);
      headers.set("shared-key", window._env_?.REACT_APP_SHARED_KEY);

      const { clientId, clientSecret, uniqueDeviceIdentifier } =
        await getClientCredentials();

      headers.set("device-id", uniqueDeviceIdentifier);
      headers.set("client-id", clientId);
      headers.set("client-secret", clientSecret);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
