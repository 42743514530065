import React, { useState } from "react";
import { ReactComponent as ScanTicketIcon } from "assets/images/scan-ticket.svg";
import LoadingIndicator from "components/Reusable/LoadingIndicator";
import { Box, Modal, Text } from "components/smalt";
import { useTranslation } from "react-i18next";
import { useZxing } from "react-zxing";
import styled from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";
import { extractFlightNumber } from "utils/scan.util";

import FixedOverlay from "./ticketScannerModalContent/FixedOverlay";
import TryAgainModalContent from "./ticketScannerModalContent/TryAgainModalContent";

const FullScreenCamera = styled.div`
  top: 0;
  height: 100svh;
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndex.modalClose};
  background-color: ${(props) => props.theme.colors.backgroundQuaternary};
`;

function TicketScanner({
  handleGetTicketNumber,
  isScanUnsuccessful,
  setIsScanUnsuccessful,
  setIsVideoModalOpen,
  setIsCameraPermissionModalOpen,
}) {
  const [, setIsTryAgainModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const { ref: videoRef } = useZxing({
    onDecodeResult(result) {
      const scannedText = result.getText();
      if (!isScanUnsuccessful) {
        handleGetTicketNumber(extractFlightNumber(scannedText));
      }
    },
    options: {
      hints: new Map([[3 /* DecodeHintType.TRY_HARDER */, true]]),
    },
    constraints: {
      video: {
        facingMode: "environment",
        width: { ideal: 1280 },
        height: { ideal: 720 },
        advanced: [
          {
            width: { min: 1280, max: 1920 },
            height: { min: 720, max: 1080 },
            aspectRatio: 16 / 9,
            facingMode: "environment",
          },
        ],
      },
    },
    onError(error) {
      if (error.toString() === "NotAllowedError: Permission denied") {
        setIsCameraPermissionModalOpen(true);
      }
    },
  });

  const isVideoLoading = !videoRef?.current;

  return (
    <FullScreenCamera>
      {isVideoLoading ? (
        <Box w="100%" position="absolute" top="0" h="100vh">
          <LoadingIndicator height="100%" />
        </Box>
      ) : (
        <FixedOverlay
          isScanUnsuccessful={isScanUnsuccessful}
          setIsVideoModalOpen={setIsVideoModalOpen}
        />
      )}
      <Box
        style={{
          //Hidden while the video is loading but still needs to be in the DOM
          visibility: videoRef?.current ? "visible" : "hidden",
        }}
      >
        <video
          style={{
            width: "100%",
            height: "100svh",
            objectFit: "cover",
          }}
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />
        <Box
          position="absolute"
          bottom="80px"
          left="50%"
          w="165px"
          d="flex"
          direction="column"
          gap="s"
          justifyContent="center"
          alignItems="center"
          style={{ transform: "translateX(-50%)" }}
        >
          <ScanTicketIcon />
          <Text
            textAlign="center"
            color="textQuaternary"
            style={getTranslationsStyle(i18n, { textAlign: "center" })}
          >
            {t("scan.instructions")}
          </Text>
        </Box>
      </Box>
      <Modal
        isOpen={isScanUnsuccessful}
        onRequestClose={() => setIsTryAgainModalOpen(false)}
        withCloseButton={false}
        w="90%"
        verticalPosition="center"
      >
        <TryAgainModalContent
          setIsScanUnsuccessful={setIsScanUnsuccessful}
          setIsTryAgainModalOpen={setIsTryAgainModalOpen}
        />
      </Modal>
    </FullScreenCamera>
  );
}

export default TicketScanner;
