import React from "react";
import { hook } from "@airportlabs/automation-hooks";
import { getColor } from "@airportlabs/smalt/src/helpers";
import ScreenReaderText from "@airportlabs/smalt/src/Typography/ScreenReaderText";
import PropTypes from "prop-types";
import styled, { css, useTheme } from "styled-components";

export const togglePropTypes = {
  /**
   * Name for the input field
   */
  name: PropTypes.string,
  /**
   * Value for the field; in our case can also be a boolean since we are also using toggles true/false fields
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Id for the checkbox field
   */
  id: PropTypes.string,
  /**
   * Weather or not the checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * Label used for screen reader in the form "label" on / off
   */
  readerLabel: PropTypes.string,
  /**
   * A valid color name used for background when the toggle is "on"
   */
  colorOn: PropTypes.string,
  /**
   * A valid color name used for background when the toggle is "off"
   */
  colorOff: PropTypes.string,
  /** When `true` it wraps the toggle in a wrapper that has the height of an input and aligns the toggle vertically in the center */
  fieldsAligned: PropTypes.bool,
  /** Control the Toggle size */
  size: PropTypes.oneOf(["small", "default", "big"]),
  /**
   * Used for automation testing
   * Will append the `toggle/toggle-checked` suffix
   */
  automationHook: PropTypes.string,
};

const tglSizeSmall = "12px";
const tglPaddingSmall = "2px";
const tglExtraWidthSmall = "3px";
const tglTranslateSmall = "15px"; // tglSize + tglExtraWidth
const tglTranslateSmallValue = 15; // tglSize + tglExtraWidth number

//Pocket Flights new toggle size "big"
const tglSizeBig = "22px";
const tglPaddingBig = "2px";
const tglExtraWidthBig = "3px";
const tglTranslateBig = "25px"; // tglSize + tglExtraWidth
const tglTranslateBigValue = 25; // tglSize + tglExtraWidth number

const tglSize = "16px";
const tglPadding = "2px";
const tglExtraWidth = "3px";
const tglTranslate = "19px";
const tglTranslateValue = 19;

const Handle = styled.div`
  display: block;
  height: ${tglSize};
  width: ${tglSize};
  border-radius: 50%;
  background: white;
  top: ${tglPadding};
  left: ${tglPadding};
  position: absolute;

  transform: ${(props) =>
    props.borderColor ? "translate(-1px, -1px)" : "translateX(0)"};

  transition: all 0.2s ease;

  ${(props) =>
    props.size &&
    props.size === "small" &&
    css`
      height: ${tglSizeSmall};
      width: ${tglSizeSmall};
      top: ${tglPaddingSmall};
      left: ${tglPaddingSmall};
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    `}
  ${(props) =>
    props.size &&
    props.size === "big" &&
    css`
      height: ${tglSizeBig};
      width: ${tglSizeBig};
      top: ${tglPaddingBig};
      left: ${tglPaddingBig};
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    `}
`;

const Container = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  height: calc(${tglSize} + 2 * ${tglPadding});
  width: calc(2 * ${tglSize} + 2 * ${tglPadding} + ${tglExtraWidth});
  border-radius: ${tglSize};
  background-color: ${(props) =>
    getColor(props, "colorOff", props.theme.toggles.colorOff)};

  input:checked ~ & {
    background-color: ${(props) =>
      getColor(props, "colorOn", props.theme.toggles.colorOn)};
  }

  ${(props) =>
    props.size &&
    props.size === "small" &&
    css`
      height: calc(${tglSizeSmall} + 2 * ${tglPaddingSmall});
      width: calc(
        2 * ${tglSizeSmall} + 2 * ${tglPaddingSmall} + ${tglExtraWidthSmall}
      );
      border-radius: ${tglSizeSmall};
    `}

  ${(props) =>
    props.size &&
    props.size === "big" &&
    css`
      height: calc(${tglSizeBig} + 2 * ${tglPaddingBig});
      width: calc(
        2 * ${tglSizeBig} + 2 * ${tglPaddingBig} + ${tglExtraWidthBig}
      );
      border-radius: ${tglSizeBig};
    `}


  border: 1px solid
    ${(props) =>
    getColor(props, "borderColor", props.theme.toggles.borderColor)};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  ${(props) =>
    props.full &&
    css`
      display: block;
      width: 100%;
    `};

  input[type="checkbox"] {
    outline: none;
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }

  input:checked ~ ${Container} {
    ${Handle} {
      transform: translateX(${tglTranslate});
      transform: ${(props) => {
        return props.borderColor
          ? `translate(${tglTranslateValue - 1}px, -1px)`
          : `translateX(${tglTranslate})`;
      }};

      transition: all 0.2s ease;
    }

    ${(props) =>
      props.size &&
      props.size === "small" &&
      css`
        ${Handle} {
          transform: ${(props) => {
            return props.borderColor
              ? `translate(${tglTranslateSmallValue - 1}px, -1px)`
              : `translateX(${tglTranslateSmall})`;
          }};
        }
      `}
    ${(props) =>
      props.size &&
      props.size === "big" &&
      css`
        ${Handle} {
          transform: ${(props) => {
            return props.borderColor
              ? `translate(${tglTranslateBigValue - 1}px, -1px)`
              : `translateX(${tglTranslateBig})`;
          }};
        }
      `};
  }

  input:disabled ~ ${Container} {
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${(props) =>
    props.fieldsAligned &&
    css`
      height: ${props.theme.fields.height};
      display: flex;
      align-items: center;
    `}
`;

const Toggle = ({
  value,
  name,
  id,
  checked,
  readerLabel,
  colorOn,
  colorOff,
  fieldsAligned,
  size,
  automationHook,
  borderColor,
  ...rest
}) => {
  const theme = useTheme();
  size = size || theme.toggles.size;

  return (
    <Wrapper
      fieldsAligned={fieldsAligned}
      size={size}
      borderColor={borderColor}
      {...hook(automationHook, checked ? "toggle-checked" : "toggle")}
    >
      <input
        type="checkbox"
        id={id || `${name}_${value}`}
        value={value}
        checked={checked}
        {...rest}
      />
      <Container
        htmlFor={id || `${name}_${value}`}
        colorOn={colorOn}
        colorOff={colorOff}
        size={size}
        borderColor={borderColor}
      >
        <Handle size={size} borderColor={borderColor} />
        <ScreenReaderText>
          {readerLabel} {checked ? "On" : "Off"}
        </ScreenReaderText>
      </Container>
    </Wrapper>
  );
};

Toggle.propTypes = togglePropTypes;

export default Toggle;
