import React from "react";
import { Box, Icon } from "components";
import styled, { useTheme } from "styled-components";

const SquareStyled = styled.div`
  outline: 9999px solid rgba(0, 0, 0, 0.3); //This creates a fullscreen opacity around the square
  width: 200px;
  height: 200px;
  position: absolute;
  left: 10%;
  top: 28.5%;
  height: 35%;
  width: 80%;
  border-radius: 20px;
`;

const TopLeftCornerStyled = styled.div`
  width: 50px;
  height: 60px;
  position: absolute;
  right: 9.4%;
  top: 28.2%;
  border-top-right-radius: 20px;
  border-top: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
  border-right: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
`;

const TopRightCornerStyled = styled.div`
  width: 50px;
  height: 60px;
  position: absolute;
  left: 9.4%;
  top: 28.2%;
  border-top-left-radius: 20px;
  border-top: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
  border-left: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
`;

const BottomLeftCornerStyled = styled.div`
  width: 50px;
  height: 60px;
  position: absolute;
  left: 9.4%;
  bottom: 36.2%;
  border-bottom-left-radius: 20px;
  border-bottom: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
  border-left: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
`;

const BottomRightCornerStyled = styled.div`
  width: 50px;
  height: 60px;
  position: absolute;
  right: 9.4%;
  bottom: 36.2%;
  border-bottom-right-radius: 20px;
  border-bottom: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
  border-right: 4px solid
    ${(props) =>
      props.isScanUnsuccessful
        ? props.theme.colors.error
        : props.theme.colors.backgroundQuaternary};
`;

const FixedOverlay = ({ isScanUnsuccessful, setIsVideoModalOpen }) => {
  const theme = useTheme();
  return (
    <>
      <SquareStyled />
      <TopLeftCornerStyled isScanUnsuccessful={isScanUnsuccessful} />
      <TopRightCornerStyled isScanUnsuccessful={isScanUnsuccessful} />
      <BottomLeftCornerStyled isScanUnsuccessful={isScanUnsuccessful} />
      <BottomRightCornerStyled isScanUnsuccessful={isScanUnsuccessful} />
      <Box
        onClick={() => setIsVideoModalOpen(false)}
        position="absolute"
        top="30px"
        left="30px"
        style={{ zIndex: theme.zIndex.modalClose }}
      >
        <Icon size="xl" color="backgroundQuaternary" name="Close"></Icon>
      </Box>
    </>
  );
};

export default FixedOverlay;
