import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetLastSelectedFlightItem,
  setSelectedFlightDetails,
} from "redux/slices/flightsList.slice";
import styled, { css, keyframes } from "styled-components";

import Content from "./flightItem/Content";
import Footer from "./flightItem/Footer";
import Header from "./flightItem/Header";

export const DEFAULT_FLIGHT_CARD_HEIGHT = 145;
export const VIA_FLIGHT_CARD_HEIGHT = 170;

const FlightItem = ({
  flight = {},
  terminalToFilter = "",
  scrolledAmountInList = 0,
  isHighlighted = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFlightItemClick = () => {
    dispatch(setSelectedFlightDetails(flight));
    navigate(`/flight/${flight.ref_id}`, {
      state: {
        terminalToFilter,
        scrolledAmountInList,
      },
    });
  };

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => {
        dispatch(resetLastSelectedFlightItem());
      }, 600);
    }
  }, [dispatch, isHighlighted]);

  return (
    <FlightItemStyled
      isHighlighted={isHighlighted}
      onClick={handleFlightItemClick}
    >
      <Header
        flightTime={flight.flight_time}
        delayedTime={flight.new_time}
        status={flight.status}
      />
      <Content
        type={flight.type}
        via={flight.via}
        destination={flight.destination}
        origin={flight.origin}
        number={flight.flight_no}
      />
      <Footer
        airlineName={flight.airline?.name}
        mediaUrl={flight.media_url}
        gate={flight.gate}
        type={flight.type}
        belt={flight.belt}
        terminal={flight.terminal_name}
      />
    </FlightItemStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

const FlightItemStyled = styled.div`
  margin: 0 ${(props) => props.theme.spaces.m};
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.flightItem};
  animation: ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          ${pulseAnimation} 0.6s ease-in-out
        `
      : "none"};
`;

export default FlightItem;
