import React from "react";
import { Box, Text } from "components";
import InfoIcon from "local_modules/smalt/src/Icons/components/base/Info";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const CameraPermissionModalContent = ({
  handleCloseModal,
  setIsVideoModalOpen,
}) => {
  const { t, i18n } = useTranslation();

  const shouldReverseOrder = isArabic(i18n);

  return (
    <Box
      mV="m"
      d="flex"
      direction="column"
      gap="m"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        d="flex"
        gap="s"
        alignItems="center"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <InfoIcon />
        <Text fontSize="l" color="otherText" style={getTranslationsStyle(i18n)}>
          {t("scan.permission_denied")}
        </Text>
      </Box>
      <Text
        color="textSecondary"
        fontSize="m"
        mH="l"
        textAlign="center"
        style={getTranslationsStyle(i18n)}
      >
        {t("scan.enable_permissions")}
      </Text>
      <Box
        onClick={() => {
          handleCloseModal(false);
          setIsVideoModalOpen(false);
        }}
      >
        <Text fontSize="h1" color="primary" style={getTranslationsStyle(i18n)}>
          {t("scan.close")}
        </Text>
      </Box>
    </Box>
  );
};

export default CameraPermissionModalContent;
