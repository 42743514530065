import React from "react";
import { Box, Text } from "components";
import theme from "components/theme";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";

const TicketInfoBoxStyled = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const AirlineInfoBox = ({ airlineName, flightNumber }) => {
  const { t, i18n } = useTranslation();
  return (
    <TicketInfoBoxStyled>
      <Box
        flex="1"
        d="flex"
        direction="column"
        justifyContent="space-between"
        gap="xxs"
      >
        <Text
          style={getTranslationsStyle(i18n, { marginRight: theme.spaces.base })}
          color="textPrimary"
        >
          {t("details.airlineInfo_airline")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n, { marginRight: theme.spaces.base })}
          fontSize="m"
          color="otherText"
        >
          {airlineName}
        </Text>
      </Box>
      <Box
        flex="1"
        d="flex"
        direction="column"
        justifyContent="space-between"
        gap="xxs"
        bL
      >
        <Text style={getTranslationsStyle(i18n)} color="textPrimary" mL="m">
          {t("details.airlineInfo_flightNumber")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n)}
          fontSize="m"
          color="otherText"
          mL="m"
        >
          {flightNumber}
        </Text>
      </Box>
    </TicketInfoBoxStyled>
  );
};

export default AirlineInfoBox;
