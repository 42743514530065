import React from "react";
import { ReactComponent as PocketFlightsLogo } from "assets/images/pocket-flights-logo.svg";
import { Box } from "components";
import LanguageSwitcher from "components/Reusable/LanguageSwitcher";
import { useTheme } from "styled-components";

const Logos = ({ logoURL }) => {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      pH="l"
      pV="s"
      bB
      borderColor="headerBorder"
    >
      <Box d="flex" gap="xs">
        {logoURL && (
          <img
            width={theme.logo.realmLogoWidth}
            src={logoURL}
            alt="realm-logo"
          />
        )}
      </Box>
      <Box d="flex" gap="s">
        <PocketFlightsLogo
          width={
            theme.logo.pocketFlightsLogoWidth || theme.logo.pocketFlightsWidth
          }
          height={
            theme.logo.pocketFlightsLogoHeight || theme.logo.pocketFlightsHeight
          }
          fill={theme.logo.pocketFlightsLogoFill || theme.colors.primary}
        />
        <LanguageSwitcher />
      </Box>
    </Box>
  );
};

export default Logos;
