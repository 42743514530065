import { Box, Text } from "components";
import theme from "components/theme";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle } from "utils/arabisation.util";

export default function DeparturesInfo({
  hideCheckin = false,
  checkin,
  gate,
  terminalName,
}) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {hideCheckin ? null : (
        <Box
          flex="1"
          d="flex"
          direction="column"
          gap="xxs"
          justifyContent="space-between"
          mR="xxs"
        >
          <Text
            style={getTranslationsStyle(i18n, {
              marginRight: theme.spaces.base,
            })}
            color="textPrimary"
          >
            {t("details.flightInfo_checkIn")}
          </Text>
          <Text
            style={getTranslationsStyle(i18n, {
              marginRight: theme.spaces.base,
            })}
            fontSize="m"
            color="otherText"
          >
            {checkin}
          </Text>
        </Box>
      )}
      <Box
        flex="1"
        d="flex"
        direction="column"
        gap="xxs"
        justifyContent="space-between"
        bL={hideCheckin ? null : true}
        mR="xxs"
      >
        <Text
          style={getTranslationsStyle(i18n, {
            marginRight: theme.spaces.base,
          })}
          color="textPrimary"
          mL={hideCheckin ? null : "m"}
        >
          {t("details.flightInfo_departures_gate")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n, {
            marginRight: theme.spaces.base,
          })}
          fontSize="m"
          color="otherText"
          mL={hideCheckin ? null : "m"}
        >
          {gate}
        </Text>
      </Box>
      <Box
        flex="1"
        d="flex"
        direction="column"
        gap="xxs"
        justifyContent="space-between"
        bL
        mR="xxs"
      >
        <Text style={getTranslationsStyle(i18n)} color="textPrimary" mL="m">
          {t("details.flightInfo_terminal")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n)}
          fontSize="m"
          color="otherText"
          mL="m"
        >
          {terminalName}
        </Text>
      </Box>
    </>
  );
}
