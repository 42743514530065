import React from "react";
import { Box, Icon, Text } from "components";
import { NOTIFICATION_CASES } from "configs/constants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";

const LearnMoreContent = ({ notificationType }) => {
  const { t, i18n } = useTranslation();

  switch (notificationType) {
    case NOTIFICATION_CASES.IOS_VERSION_BELOW_16_4:
      return (
        <Text
          style={getTranslationsStyle(i18n, {
            textAlign: "justify",
          })}
          fontSize="m"
        >
          {t("notifications_instructions.informationText_iOSVersionBelow16_4")}
        </Text>
      );
    case NOTIFICATION_CASES.DEFAULT_BROWSER_NOT_SAFARI:
      return (
        <Text style={getTranslationsStyle(i18n)} fontSize="m">
          {t(
            "notifications_instructions.informationText_defaultBrowserNotSafari"
          )}
        </Text>
      );
    case NOTIFICATION_CASES.APP_NOT_INSTALLED_AS_PWA:
      return (
        <>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;1.&nbsp;
            </Text>
            {t("installation_modals.ios_text1")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;2.&nbsp;
            </Text>
            {t("installation_modals.ios_text2")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;3.&nbsp;
            </Text>
            {t("installation_modals.ios_text3")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;4.&nbsp;
            </Text>
            {t("installation_modals.ios_text4")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} mB="m" fontSize="m">
            <Text as="span" fontWeight="semibold">
              &nbsp;5.&nbsp;
            </Text>
            {t("installation_modals.ios_text5")}
          </Text>
          <Text
            style={getTranslationsStyle(i18n, { textAlign: "justify" })}
            fontSize="m"
          >
            {t("installation_modals.ios_text6")}
          </Text>
        </>
      );
    case NOTIFICATION_CASES.IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA:
      return (
        <Box>
          <Text
            mB="m"
            fontSize="m"
            style={getTranslationsStyle(i18n, { textAlign: "justify" })}
          >
            {t("installation_modals.android_text1")}
          </Text>
          <Text fontSize="m" style={getTranslationsStyle(i18n)}>
            <Text
              as="span"
              fontWeight="semibold"
              style={getTranslationsStyle(i18n)}
            >
              &nbsp;1.&nbsp;
            </Text>
            {t("installation_modals.android_text2")}
          </Text>
          <Text fontSize="m" style={getTranslationsStyle(i18n)}>
            <Text
              as="span"
              fontWeight="semibold"
              style={getTranslationsStyle(i18n)}
            >
              &nbsp;2.&nbsp;
            </Text>
            {t("installation_modals.android_text3")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text
              as="span"
              fontWeight="semibold"
              style={getTranslationsStyle(i18n)}
            >
              &nbsp;3.&nbsp;
            </Text>
            {t("installation_modals.android_text4")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} fontSize="m">
            <Text
              as="span"
              fontWeight="semibold"
              style={getTranslationsStyle(i18n)}
            >
              &nbsp;4.&nbsp;
            </Text>
            {t("installation_modals.android_text5")}
          </Text>
          <Text style={getTranslationsStyle(i18n)} mB="m" fontSize="m">
            <Text
              as="span"
              fontWeight="semibold"
              style={getTranslationsStyle(i18n)}
            >
              &nbsp;5.&nbsp;
            </Text>
            {t("installation_modals.android_text6")}
          </Text>
          <Text
            style={getTranslationsStyle(i18n)}
            textAlign="justify"
            fontSize="m"
          >
            {t("installation_modals.android_text7")}
          </Text>
        </Box>
      );
    case NOTIFICATION_CASES.USER_IS_NOT_BROWSING_MOBILE:
      return (
        <Text
          fontSize="m"
          style={getTranslationsStyle(i18n, {
            textAlign: "justify",
          })}
        >
          {t("installation_modals.userIsNotBrowsingMobile")}
        </Text>
      );
    default:
      return null;
  }
};

const NotificationModalStyled = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundQuaternary};
  box-shadow: ${(props) => props.theme.boxShadow.modal};
  border-radius: ${(props) => props.theme.borderRadius.m};
  width: 100%;
`;

const NotificationInstructionsModalContent = ({
  notificationType,
  handleCloseNotificationsModal,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <NotificationModalStyled>
      <Box h="100%" d="flex" direction="column" alignItems="center">
        <Box
          d="flex"
          gap="s"
          justifyContent="center"
          alignItems="center"
          pV="m"
        >
          <Icon size="xl" name="Info" />
          <Text
            fontSize="l"
            fontWeight="semibold"
            style={getTranslationsStyle(i18n)}
          >
            {t("notifications_instructions.receiveNotifications")}
          </Text>
        </Box>
        <Box pH="m">
          <LearnMoreContent notificationType={notificationType} />
        </Box>
        <Box mT="l" mB="base">
          <Text
            style={getTranslationsStyle(i18n)}
            fontSize="l"
            color="primary"
            fontWeight="semibold"
            onClick={() =>
              handleCloseNotificationsModal((previousState) => !previousState)
            }
          >
            {t("installation_modals.close")}
          </Text>
        </Box>
      </Box>
    </NotificationModalStyled>
  );
};

export default NotificationInstructionsModalContent;
