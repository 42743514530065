import React from "react";
import { Box } from "components";
import AirlineLogo from "components/Reusable/AirlineLogo";
import { VARIANTS } from "configs/constants";
import styled from "styled-components";

import AirportsAndVias from "./ticketInfoBox/AirportsAndVias";
import PlaneLine from "./ticketInfoBox/PlaneLine";
import TimeInformation from "./ticketInfoBox/TimeInformation";

const TicketInfoBox = ({
  airlineName,
  mediaUrl,
  newTime,
  status,
  type,
  flightTime,
  via,
  airport,
}) => {
  return (
    <TicketInfoBoxStyled>
      <Box d="flex" justifyContent="center" mB="base">
        <AirlineLogo
          maximumRetriesCount={2}
          mediaUrl={mediaUrl}
          airlineName={airlineName}
          variant={VARIANTS.AIRLINE_LOGO.DETAILS_PAGE}
        />
      </Box>
      <TimeInformation
        type={type}
        newTime={newTime}
        status={status}
        flightTime={flightTime}
      />
      <PlaneLine type={type} statusText={status?.text} />
      <AirportsAndVias type={type} airport={airport} flightVias={via} />
    </TicketInfoBoxStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const TicketInfoBoxStyled = styled.div`
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  padding-top: 0; // make room for the Airline logo
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
  margin-top: ${(props) => props.theme.TicketInfoVerticalOffset};
`;

export default TicketInfoBox;
