import { Box, Text } from "components";
import theme from "components/theme";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle } from "utils/arabisation.util";

export default function ArrivalsInfo({ belt, terminal }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box flex="1" d="flex" direction="column" gap="xxs">
        <Text
          style={getTranslationsStyle(i18n, {
            marginRight: theme.spaces.base,
          })}
          color="textPrimary"
        >
          {t("details.flightInfo_arrivals_baggageBelt")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n, {
            marginRight: theme.spaces.base,
          })}
          fontSize="m"
          color="otherText"
        >
          {belt}
        </Text>
      </Box>
      <Box flex="1" d="flex" direction="column" gap="xxs" bL>
        <Text style={getTranslationsStyle(i18n)} color="textPrimary" mL="m">
          {t("details.flightInfo_terminal")}
        </Text>
        <Text
          style={getTranslationsStyle(i18n)}
          fontSize="m"
          color="otherText"
          mL="m"
        >
          {terminal}
        </Text>
      </Box>
    </>
  );
}
