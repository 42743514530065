import { useEffect, useState } from "react";

const useIOSDetection = () => {
  const [isMacOS, setIsMacOS] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isMobileSafari, setIsMobileSafari] = useState(false);
  const [iOSVersion, setIOSVersion] = useState("");
  const [isIOSVersionGreaterThan16_4, setIsIOSVersionGreaterThan16_4] =
    useState(false);

  useEffect(() => {
    const platform = navigator.platform.toUpperCase();
    const userAgent = navigator.userAgent.toLowerCase();

    setIsMacOS(platform.indexOf("MAC") >= 0);
    setIsChrome(
      userAgent.indexOf("chrome") >= 0 && userAgent.indexOf("safari") === -1
    );

    const isMobileSafari =
      /iP(ad|od|hone)/i.test(navigator.userAgent) &&
      /WebKit/i.test(navigator.userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent);

    setIsMobileSafari(isMobileSafari);

    setIsSafari(
      userAgent.indexOf("safari") >= 0 && userAgent.indexOf("chrome") === -1
    );

    if (/(iPad|iPhone|iPod)/i.test(userAgent)) {
      const match = userAgent.match(/OS (\d+([_\.]\d+){1,2})/i);
      if (match && match.length > 1) {
        const version = match[1].replace(/_/g, ".");
        setIOSVersion(version);

        // Compare iOS version with "16.4.0"
        const isVersionGreaterThan1640 = compareVersions(version, "16.4.0") > 0;
        setIsIOSVersionGreaterThan16_4(isVersionGreaterThan1640);
      }
    } else if (isMacOS) {
      const match = userAgent.match(/Mac OS X (\d+([_\.]\d+){1,2})/i);
      if (match && match.length > 1) {
        const version = match[1].replace(/_/g, ".");
        setIOSVersion(version);

        // Compare macOS version with "10.0.0"
        const isVersionGreaterThan1640 = compareVersions(version, "10.0.0") > 0;
        setIsIOSVersionGreaterThan16_4(isVersionGreaterThan1640);
      }
    }
  }, [isMacOS]);

  // Helper function to compare versions
  const compareVersions = (versionA, versionB) => {
    const partsA = versionA.split(".").map(Number);
    const partsB = versionB.split(".").map(Number);

    for (let i = 0; i < partsA.length; i++) {
      if (partsA[i] > partsB[i]) {
        return 1;
      } else if (partsA[i] < partsB[i]) {
        return -1;
      }
    }

    return 0;
  };

  return {
    isMacOS,
    isChrome,
    isSafari,
    isMobileSafari,
    iOSVersion,
    isIOSVersionGreaterThan16_4,
  };
};

export default useIOSDetection;
