import React from "react";
import { Box, Text } from "components";
import theme from "components/theme";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";

const CustomCheckinStyled = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.backgroundQuaternary};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.ticketInfoBox};
  padding: ${(props) => props.theme.spaces.base};
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
`;

const CustomCheckin = ({ checkins = [] }) => {
  const { t, i18n } = useTranslation();

  return (
    <CustomCheckinStyled>
      <Box w="100%" d="flex" direction="column" justifyContent="center">
        <Box d="flex" w="100%" mB="base" justifyContent="space-between">
          <Text
            color="textPrimary"
            style={getTranslationsStyle(i18n, {
              flex: 1,
              paddingRight: theme.spaces.base,
            })}
          >
            {t("details.flightInfo_checkIn")}
          </Text>
          <Text
            color="textPrimary"
            style={getTranslationsStyle(i18n, {
              flex: 1.8,
            })}
          >
            {t("details.flightInfo_departures_customCheckin_area")}
          </Text>
        </Box>
        <Box
          style={getTranslationsStyle(i18n)}
          flex="1"
          w="100%"
          d="flex"
          direction="column"
          gap="s"
        >
          {checkins.map((checkIn) => {
            const areas = checkIn.areas || [];
            return (
              <Box key={checkIn.name} d="flex" alignItems="center">
                <Box flex="1.8">
                  <Text style={getTranslationsStyle(i18n)}>
                    {/* TODO Check if the data from BE is translated */}
                    {checkIn?.name}
                  </Text>
                </Box>
                <Box
                  d="flex"
                  justifyContent="flex-end"
                  flex="1"
                  style={getTranslationsStyle(i18n, {
                    justifyContent: "flex-start",
                  })}
                >
                  {areas?.map((area, index) => (
                    <Text
                      style={getTranslationsStyle(i18n)}
                      key={area.name}
                      textAlign="right"
                      fontSize="l"
                    >
                      {area.name}
                      {index !== areas.length - 1 && ","}
                    </Text>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CustomCheckinStyled>
  );
};

export default CustomCheckin;
