import React from "react";
import { ReactComponent as DropdownIcon } from "assets/images/icon-dropdown.svg";
import { Box, Text } from "components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  languageDrawerSelector,
  setIsOpen,
} from "redux/slices/languageDrawer.slice";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { isOpen: isLanguageDrawerOpen } = useSelector(languageDrawerSelector);
  const dispatch = useDispatch();
  const languageCode = i18n.language;

  const handleClick = () => {
    dispatch(setIsOpen(true));
  };

  return (
    <Box
      d="flex"
      pH="xxs"
      b
      borderColor="headerBorder"
      borderRadius="m"
      alignItems="center"
      onClick={handleClick}
    >
      <Box pL="xxs">
        <Text fontWeight="semibold">{languageCode.toUpperCase()}</Text>
      </Box>
      <DropdownIcon
        style={{
          transform: isLanguageDrawerOpen ? "rotate(180deg)" : undefined,
        }}
      />
    </Box>
  );
};

export default LanguageSwitcher;
