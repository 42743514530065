import styled, { css } from "styled-components";

import { darkenSafe, getColor, getSpace } from "../helpers";
// Import order issue when importing directly from "../mixins"
import { paddingMixin } from "../Containers/mixins";

export const buttonTotalResetMixin = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const ButtonStyled = styled.button.attrs((props) => ({
  w: props.width || props.w,
  h: props.height || props.h,
}))`
  ${buttonTotalResetMixin};
  display: flex;
  position: relative;
  font-weight: normal;
  color: ${(props) => getColor(props, "textColor", "textInverted")};
  background: ${(props) => getColor(props, "color", "primary")};
  border: 1px solid ${(props) => getColor(props, "color", "primary")};
  border-radius: ${(props) => props.theme.borderRadius.base};
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
  min-width: ${(props) => props.theme.buttons.minW};
  /* in case we get a link as component */
  text-decoration: none;
  text-align: ${(props) => props.textAlign || "center"};
  text-transform: ${(props) => props.theme.buttons.textTransform};
  font-size: ${(props) => props.theme.fields.fontSize};
  line-height: ${(props) => props.theme.fields.lineHeight};

  /** flex related props */
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  ${(props) =>
    props.gap &&
    css`
      gap: ${getSpace(props, "gap", "xs")};
    `};

  /** paddings */
  padding: ${(props) => `${props.theme.fields.pV} ${props.theme.fields.pH}`};
  ${paddingMixin};

  ${(props) =>
    props.w &&
    css`
      width: ${props.w};
      min-width: auto;
    `}

  &:hover,
  &:focus {
    color: ${(props) =>
      props.$hoverTextColor
        ? getColor(props, "$hoverTextColor", "textInverted")
        : getColor(props, "textColor", "textInverted")};
    background: ${(props) =>
      props.$hoverColor
        ? getColor(props, "$hoverColor", "transparent")
        : darkenSafe(0.1, getColor(props, "color", "primary"))};
    border-color: ${(props) =>
      props.$hoverColor
        ? getColor(props, "$hoverColor", "transparent")
        : darkenSafe(0.1, getColor(props, "color", "primary"))};

    ${(props) =>
      props.variant === "filledShadowHover" &&
      css`
        box-shadow: ${props.theme.boxShadow.buttonHover};
      `}
  }

  &[disabled] {
    opacity: ${(props) => props.theme.buttons.disableOpacity};
    pointer-events: none;
    cursor: not-allowed;
  }

  /** Allow a height for buttons, but make sure there's an extra wrapper around the button content , so the content gets properly vertically aligned
    * Use with extreme caution, as this will not allow for the button to grow in size, so if text wraps on two lines, it will look bad
    */
  ${(props) =>
    props.h &&
    css`
      height: ${props.h === "fieldsHeight"
        ? props.theme.fields.height
        : props.h};
      padding-top: 0;
      padding-bottom: 0;
    `};

  ${(props) =>
    props.variant &&
    props.variant === "border" &&
    css`
      background-color: transparent;
      color: ${props.textColor
        ? getColor(props, "textColor", "text")
        : getColor(props, "color", "primary")};
      border: 1px solid ${getColor(props, "color", "primary")};

      &:hover,
      &:focus {
        color: ${props.$hoverTextColor
          ? getColor(props, "$hoverTextColor", "text")
          : props.textColor
          ? darkenSafe(0.1, getColor(props, "textColor", "text"))
          : props.$hoverColor
          ? getColor(props, "$hoverColor", "primary")
          : darkenSafe(0.1, getColor(props, "color", "primary"))};
        background-color: transparent;
        border-color: ${props.$hoverColor
          ? getColor(props, "$hoverColor", "primary")
          : darkenSafe(0.1, getColor(props, "color", "primary"))};
      }
    `};

  ${(props) =>
    props.variant &&
    (props.variant === "transparent" || props.variant === "incognito") &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
      color: ${getColor(props, "color", "primary")};
      width: auto;
      min-width: auto;

      ${(props) =>
        props.w &&
        css`
          width: ${props.w};
        `}

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: transparent;
        color: ${props.$hoverColor
          ? getColor(props, "$hoverColor", "text")
          : darkenSafe(0.1, getColor(props, "color", "primary"))};
      }

      ${props.variant === "incognito" &&
      css`
        font-size: inherit;
        line-height: inherit;
        text-transform: inherit;
        font-weight: inherit;
        padding: 0;
      `}
    `};

  /* This variant is similar to the filled button one, but has different defaults and a box-shadow,
    *  and on hover/focus it will darken the text color and box-shadow, but NOT the background */
  ${(props) =>
    props.variant &&
    props.variant === "shadow" &&
    css`
      color: ${getColor(props, "textColor", "primary")};
      background: ${getColor(props, "color", "backgroundPrimary")};
      border-color: ${getColor(props, "color", "backgroundPrimary")};
      box-shadow: ${props.theme.boxShadow.button};

      &:hover,
      &:focus {
        color: ${props.$hoverTextColor
          ? getColor(props, "$hoverTextColor", "primary")
          : darkenSafe(0.1, getColor(props, "textColor", "primary"))};
        background: ${props.$hoverColor
          ? getColor(props, "$hoverColor", "backgroundPrimary")
          : getColor(props, "color", "backgroundPrimary")};
        border-color: ${props.$hoverColor
          ? getColor(props, "$hoverColor", "backgroundPrimary")
          : getColor(props, "color", "backgroundPrimary")};
        box-shadow: ${props.theme.boxShadow.buttonHover};
      }
    `};

  /** Like a Fill variant, but on hover has NO darkening of the background, just an extra a shadow */
  ${(props) =>
    props.variant &&
    props.variant === "shadowHover" &&
    css`
      &:hover,
      &:focus {
        /* no darkening of the background or text, just add a shadow */
        background: ${(props) =>
          props.$hoverColor
            ? getColor(props, "$hoverColor", "transparent")
            : getColor(props, "color", "primary")};
        border-color: ${(props) =>
          props.$hoverColor
            ? getColor(props, "$hoverColor", "transparent")
            : getColor(props, "color", "primary")};
        box-shadow: ${props.theme.boxShadow.buttonHover};
      }
    `};

  ${(props) =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}

  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `}
`;

export default ButtonStyled;
