import { css } from "styled-components";

export const textAlignMixin = css`
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};
`;

export const fontWeightMixin = css`
  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.theme.fontWeights[props.fontWeight] || "inherit"};
    `};
`;

export const hiddenButReaderAccessibleMixin = css`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
`;

export const truncateMixin = css`
  ${(props) =>
    props.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const truncateChildrenMixin = css`
  ${(props) =>
    props.truncateChildren &&
    css`
      flex: 1;
      min-width: 0;

      & > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}
`;
